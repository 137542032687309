import React from "react"
import { useState, useCallback } from "react"

type SettingContext = {
    setBedSizeCallback: (bedSize: BedSize) => void
    setCustomBedSizeCallback: (x: number, y: number) => void
    currentBedSize: BedSize
    bedSizes: BedSize[]
}

export const SettingsContext = React.createContext({} as SettingContext)
const DefaultBedSizes: BedSize[] = [
    {
        x: 200,
        y: 200,
    },
    {
        x: 300,
        y: 300,
    },
    {
        x: 350,
        y: 350,
    },
]

export type BedSize = {
    x: number
    y: number
    custom?: boolean
}

const getBedSizes: () => BedSize[] = () => {
    var bedSizesString = localStorage.getItem("BedSizes")
    var bedSizes
    if (bedSizesString) {
        bedSizes = JSON.parse(bedSizesString)
    }

    if (!bedSizes) {
        bedSizes = DefaultBedSizes
        bedSizesString = JSON.stringify(bedSizes)
        localStorage.setItem("BedSizes", bedSizesString)
    }
    return bedSizes
}

export function SettingsContextProvider({ children }: any) {
    var storageBedSize = localStorage.getItem("BedSize")
    var localBedSize: BedSize = DefaultBedSizes[0]
    if (storageBedSize) {
        const bedSizeJson = JSON.parse(storageBedSize)
        if (bedSizeJson) {
            localBedSize = bedSizeJson
        }
    }
    const [bedSizes, setBedSizes] = useState<BedSize[]>(getBedSizes())
    const [bedSize, setBedSize] = useState<BedSize>(localBedSize)

    const setBedSizeCallback = useCallback(
        (bedSize: BedSize) => {
            localStorage.setItem("BedSize", JSON.stringify(bedSize))
            setBedSize(bedSize)
        },
        [bedSize],
    )

    const setCustomBedSizeCallback = useCallback(
        (x: number, y: number) => {
            const currentBedSizes = getBedSizes()
            const existing = currentBedSizes.find((size) => {
                return size.x == x && size.y == y
            })
            if (!existing) {
                currentBedSizes.push({
                    x: x,
                    y: y,
                    custom: true,
                })
                const bedSizesString = JSON.stringify(currentBedSizes)
                localStorage.setItem("BedSizes", bedSizesString)
                setBedSizes(currentBedSizes)
            }
        },
        [bedSize],
    )

    const exposedData: SettingContext = {
        currentBedSize: bedSize,
        setBedSizeCallback,
        setCustomBedSizeCallback,
        bedSizes,
    }
    return <SettingsContext.Provider value={exposedData}>{children}</SettingsContext.Provider>
}
