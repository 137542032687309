import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, Property, RenderParameters } from "../model/api"

import { ModifiedProperty } from "./PropertyItem"
import { SetupPropertyItem } from "./SetupPropertyItem"

type SetupPropertiesProps = {
    properties: ModifiedProperty[]
    addedProperties: Property[]
    setAddedProperties: (modifiedProperties: any) => void
    hidden: boolean
    setHidden: any
}

function SetupProperties(props: SetupPropertiesProps) {
    const { properties, addedProperties, setAddedProperties, hidden, setHidden } = props

    const onAddressValueChange = useCallback(
        (event: any, index: number) => {
            const value = event.target.value
            const newProperties = structuredClone(addedProperties)
            const property = newProperties[index]
            property.cellAddress = value
            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    const onNameValueChange = useCallback(
        (event: any, index: number) => {
            const value = event.target.value
            const newProperties = structuredClone(addedProperties)
            const property = newProperties[index]
            property.name = value
            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    const onDescriptionValueChange = useCallback(
        (event: any, index: number) => {
            const value = event.target.value
            if (value.length <= 1024) {
                const newProperties = structuredClone(addedProperties)
                const property = newProperties[index]
                property.description = value
                setAddedProperties(newProperties)
            }
        },
        [addedProperties, properties],
    )

    const onReadonlyValueChange = useCallback(
        (event: any, index: number) => {
            const newProperties = structuredClone(addedProperties)
            const property = newProperties[index]
            property.readonly = !!!property.readonly
            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    const onTypeValueChange = useCallback(
        (event: any, index: number) => {
            const newProperties = structuredClone(addedProperties)
            const property = newProperties[index]
            if (property.type && property.type == "qty") {
                property.type = "mm"
            } else if (property.type && property.type == "mm") {
                property.type = "qty"
            } else {
                property.type = "mm"
            }

            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    const onCellAddressClick = useCallback(
        (event: any, index: number) => {
            const property = properties[index]
            if (!property) {
                return
            }

            if (addedProperties.find((prop) => prop && prop.cellAddress == property.cellAddress)) {
                return
            }

            ;(document.getElementById("my_modal_2") as any).close()
            const newProperties = structuredClone(addedProperties)
            newProperties.push({
                cellAddress: property.cellAddress,
                alias: property.alias,
                value: property.value,
            })
            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    const onRemoveProperty = useCallback(
        (event: any, index: number) => {
            const newProperties = structuredClone(addedProperties).filter(
                (property, itemIndex) => itemIndex != index,
            )
            setAddedProperties(newProperties)
        },
        [addedProperties, properties],
    )

    return (
        <div className="absolute w-full pt-20 left-0 z-100">
            <div
                className={
                    "transition-transform duration-300" +
                    (hidden ? " translate-x-[-95%] hover:translate-x-[-90%] hover:transition-scale" : "")
                }
                onClick={hidden ? setHidden : () => {}}
            >
                <div
                    className="text-right flex mt-8 justify-start bg-base-300/90 rounded-t-2xl"
                    onClick={setHidden}
                >
                    <div className="font-bold text-3xl ml-4 mt-[-28px] text-left">
                        Properties
                        <button
                            className="btn btn-ghost text-base ml-[-10px] hover:bg-transparent"
                            onClick={setHidden}
                        >
                            <svg
                                className="h-6 w-6 translate-y-1"
                                viewBox="0 0 48 48"
                                stroke="none"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.957,33.214L7.171,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.953-5.159-7.957-9.214
		                                L40.829,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.953,29.159,11.957,33.214z M16,24
    		                            c0-4.418,3.582-8,8-8c1.483,0,2.867,0.411,4.058,1.114l-3.035,3.035C24.694,20.062,24.356,20,24,20c-2.206,0-4,1.794-4,4
	    	                            c0,0.356,0.062,0.694,0.149,1.023l-3.035,3.035C16.411,26.867,16,25.483,16,24z M32,24c0,4.418-3.582,8-8,8
		                                c-1.483,0-2.867-0.411-4.058-1.114l3.035-3.035C23.306,27.938,23.644,28,24,28c2.206,0,4-1.794,4-4
		                                c0-0.356-0.062-0.694-0.149-1.023l3.035-3.035C31.589,21.133,32,22.517,32,24z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="bg-base-300/90 pt-5 overflow-y-auto max-h-[65vh] drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    {properties.length == 0 && (
                        <div className="font-bold text-lg w-full text-center pb-2">No properties</div>
                    )}
                    {properties.length > 0 && (
                        <div>
                            {addedProperties.map((property, index) => {
                                return (
                                    <div key={"propertyItem_" + property.cellAddress}>
                                        <SetupPropertyItem
                                            address={property.cellAddress}
                                            name={property.name ?? ""}
                                            description={property.description ?? ""}
                                            readonly={property.readonly ?? false}
                                            type={property.type}
                                            onAddressChange={(event: any) => {
                                                onAddressValueChange(event, index)
                                            }}
                                            onNameChange={(event: any) => {
                                                onNameValueChange(event, index)
                                            }}
                                            onDescriptionChange={(event: any) => {
                                                onDescriptionValueChange(event, index)
                                            }}
                                            onReadonlyChange={(event: any) => {
                                                onReadonlyValueChange(event, index)
                                            }}
                                            onRemove={(event: any) => {
                                                onRemoveProperty(event, index)
                                            }}
                                            onTypeChange={(event: any) => {
                                                onTypeValueChange(event, index)
                                            }}
                                        ></SetupPropertyItem>
                                        <div className="divider pl-5 py-0 my-0 w-11/12"></div>
                                    </div>
                                )
                            })}
                            <div>
                                <div className="w-full flex justify-around">
                                    <div className="tooltip tooltip-bottom" data-tip={"Add a parameter"}>
                                        <button
                                            className="btn btn-accent w-44 mb-2 m-auto"
                                            onClick={() => {
                                                ;(document.getElementById("my_modal_2") as any).showModal()
                                            }}
                                        >
                                            Add Property
                                        </button>
                                    </div>
                                </div>
                                <dialog id="my_modal_2" className="modal">
                                    <div className="modal-box pb-10 pt-0 bg-base-100">
                                        <div className="overflow-x-auto">
                                            <div className="text-center">
                                                <p className="text-xl py-4">
                                                    Freecad spreadsheet values{" "}
                                                    <button
                                                        className="btn btn-sm ml-5"
                                                        onClick={() =>
                                                            (
                                                                document.getElementById("my_modal_2") as any
                                                            ).close()
                                                        }
                                                    >
                                                        Close
                                                    </button>
                                                </p>
                                            </div>
                                            <table className="table table-pin-rows w-full">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cell Address</th>
                                                        <th>Alias</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {properties.map((property: Property, index: number) => {
                                                        if (
                                                            addedProperties.find(
                                                                (prop) =>
                                                                    prop &&
                                                                    prop.cellAddress == property.cellAddress,
                                                            )
                                                        ) {
                                                            return (
                                                                <tr
                                                                    className="opacity-50"
                                                                    key={"select_cell_" + index}
                                                                    onClick={(event: any) =>
                                                                        onCellAddressClick(event, index)
                                                                    }
                                                                >
                                                                    <th>{index + 1}</th>
                                                                    <td>{property.cellAddress}</td>
                                                                    <td>{property.alias}</td>
                                                                    <td>{property.value}</td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return (
                                                                <tr
                                                                    className="hover"
                                                                    key={"select_cell_" + index}
                                                                    onClick={(event: any) =>
                                                                        onCellAddressClick(event, index)
                                                                    }
                                                                >
                                                                    <th>{index + 1}</th>
                                                                    <td>{property.cellAddress}</td>
                                                                    <td>{property.alias}</td>
                                                                    <td>{property.value}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </dialog>
                            </div>
                        </div>
                    )}
                </div>
                <div className="pb-4 pt-2 mb-3 text-right flex justify-center bg-base-300/90 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)] rounded-b-2xl"></div>
            </div>
        </div>
    )
}

export default SetupProperties
