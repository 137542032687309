import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, Property } from "../model/api"

export type ModifiedProperty = Property & { currentValue?: string }

type PropertyItemProps = {
    property: ModifiedProperty
    onPropertyChange: (property: Property, newValue: string) => void
}

export function PropertyItem(props: PropertyItemProps) {
    const { property, onPropertyChange } = props

    const [inputValue, setInputValue] = useState<string>(property.value)

    useEffect(() => {
        setInputValue(property.currentValue ?? property.value)
    }, [property, onPropertyChange])

    const onValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            setInputValue(value)
        },
        [property],
    )

    const onBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            const number = parseFloat(value)
            if (number) {
                setInputValue(number.toString())
                onPropertyChange(property, number.toString())
            } else {
                setInputValue(property.value)
                onPropertyChange(property, property.value)
            }
        },
        [property],
    )

    return (
        <div className="px-2">
            <div className="label-text-alt text-left px-2 py-2">
                <div className="flex place-content-between">
                    <div className="label-text font-semibold text-xl text-left inline pr-2">
                        {property.name || property.alias || property.cellAddress}:
                    </div>
                    {!property.readonly && (
                        <div className="join inline ml-1 bg-transparent text-right text-nowrap">
                            <input
                                type="text"
                                className="join-item input focus:outline-none input-bordered input-sm w-20 text-right"
                                value={inputValue}
                                onChange={onValueChange}
                                onBlur={onBlur}
                            />
                            <button className="btn btn-sm btn-outline join-item bordered" disabled>
                                {property.type == "qty" ? "QTY" : "MM"}
                            </button>
                        </div>
                    )}
                    {property.readonly && (
                        <div className="label-text font-bold text-lg text-left inline ml-0 mr-2 right-4">
                            {property.currentValue || property.value} {property.type == "qty" ? "QTY" : "MM"}
                        </div>
                    )}
                </div>
            </div>
            {property.description && (
                <div>
                    {/* <div className="label-text-alt text-left px-2 pt-2 font-bold pb-1">Description:</div> */}
                    <div className="label-text-alt text-left text-base mt-2 px-2 pb-6">
                        {property.description}
                    </div>
                </div>
            )}
        </div>
    )
}
