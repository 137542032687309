import React, { act, Ref, useContext } from "react"
import { useEffect, useState, useCallback, useRef } from "react"
import "./Profile.css"

import { Cad, User, useApi, Grouping, Transaction, TransactionType, TransactionStatus } from "../model/api"

import Item from "../list/Item"

export enum ProfileTab {
    UPLOADS = "uploads",
    OWNED = "owned",
    ACCOUNT = "account",
}

type ProfileProps = {
    isMobile: boolean
    onBackButtonClicked: () => void
    user: User
    onCadClicked: (cad: Cad, index: number, newTab: boolean) => void
    onClickUploadCad: () => void
    activeTab: ProfileTab
    setActiveTab: (tab: ProfileTab) => void
    showSuccessMessage: (message: string) => void
    showErrorMessage: (message: string) => void
}

function formatDate(isoString: string) {
    const date = new Date(isoString)
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
}

export function Profile(props: ProfileProps) {
    const {
        isMobile,
        onBackButtonClicked,
        user,
        onCadClicked,
        onClickUploadCad,
        activeTab,
        setActiveTab,
        showSuccessMessage,
        showErrorMessage,
    } = props
    const {
        registerAsSeller,
        getUserGroupings,
        getOwnedCads,
        setDisplayName,
        setInvoicingData,
        setProfileData,
    } = useApi()

    const [ownedCads, setOwnedCads] = useState<Cad[]>([])
    const [groupings, setGroupings] = useState<Grouping[]>([])

    const [modifiedDisplayName, setModifiedDisplayName] = useState<string>(user.displayName)

    const [profileDescription, setProfileDescription] = useState<string>(user.description ?? "")
    const [profileYoutubeUrl, setProfileYoutubeUrl] = useState<string>(user.youtubeUrl ?? "")

    const [invoicingName, setInvoicingName] = useState<string>(user.invoicingData?.name ?? "")
    const [invoicingTaxId, setInvoicingTaxId] = useState<string>(user.invoicingData?.taxId ?? "")
    const [invoicingAddress, setInvoicingAddress] = useState<string>(user.invoicingData?.address ?? "")

    useEffect(() => {}, [])

    useEffect(() => {
        if (!user) {
            return
        }

        if (user.invoicingData) {
            setInvoicingName(user.invoicingData.name)
            setInvoicingTaxId(user.invoicingData.taxId)
            setInvoicingAddress(user.invoicingData.address)
        }

        setProfileDescription(user.description ?? "")
        setProfileYoutubeUrl(user.youtubeUrl ?? "")
        ;(async () => {
            switch (activeTab) {
                case ProfileTab.ACCOUNT:
                    break
                case ProfileTab.OWNED:
                    const ownedCads = await getOwnedCads()
                    setOwnedCads(ownedCads)
                    break
                case ProfileTab.UPLOADS:
                    const profile = await getUserGroupings(user.displayName)
                    if (profile?.groupings) {
                        setGroupings(profile.groupings)
                    }
                    break
            }
        })()
    }, [activeTab, user])

    const sellerOnboardingClicked = useCallback(() => {
        if (!user) {
            return
        }

        ;(async () => {
            const url = await registerAsSeller()
            if (url) {
                window.open(url)
            } else {
                showErrorMessage("Register as seller failed")
            }
        })()
    }, [user, registerAsSeller])

    const onDisplayNameValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 26) {
                setModifiedDisplayName(value)
            }
        },
        [user],
    )

    const onDisplayNameChange = useCallback(
        async (event: any) => {
            const result = await setDisplayName(modifiedDisplayName)
            if (result) {
                showSuccessMessage("Display name changed")
            } else {
                showErrorMessage("Display name change error")
                return
            }
        },
        [user, modifiedDisplayName],
    )

    const onInvocingNameValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 1024) {
                setInvoicingName(value)
            }
        },
        [user],
    )

    const onInvoicingTaxIdChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 1024) {
                setInvoicingTaxId(value)
            }
        },
        [user],
    )

    const onInvoicingAddressChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 1024) {
                setInvoicingAddress(value)
            }
        },
        [user],
    )

    const onProfileDescriptionChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 3072) {
                setProfileDescription(value)
            }
        },
        [user],
    )

    const onProfileYoutubeUrlChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 500) {
                setProfileYoutubeUrl(value)
            }
        },
        [user],
    )

    const onSetProfileData = useCallback(
        async (event: any) => {
            const result = await setProfileData(profileDescription, profileYoutubeUrl)
            if (result) {
                showSuccessMessage("Profile data saved")
            } else {
                showErrorMessage("Profile data error")
                return
            }
        },
        [user, profileDescription, profileYoutubeUrl],
    )

    const onSetInvoicingData = useCallback(
        async (event: any) => {
            const result = await setInvoicingData(invoicingName, invoicingTaxId, invoicingAddress)
            if (result) {
                showSuccessMessage("Invoicing data saved")
            } else {
                showErrorMessage("Invoicing data error")
                return
            }
        },
        [user, invoicingAddress, invoicingName, invoicingTaxId],
    )

    const transactionTypeToDescription = (type: TransactionType) => {
        switch (type) {
            case TransactionType.BUY:
                return "Purchase"
                break
            case TransactionType.SELL:
                return "Sold"
                break
        }
    }

    const transactionStatusDescription = (type: TransactionStatus) => {
        switch (type) {
            case TransactionStatus.COMPLETED:
                return "Completed"
                break
            case TransactionStatus.PENDING:
                return "Pending"
                break
            case TransactionStatus.FAILED:
                return "Failed"
                break
        }
    }

    function msToTime(ms: number) {
        let seconds = (ms / 1000).toFixed(1)
        let minutes = (ms / (1000 * 60)).toFixed(1)
        let hours = (ms / (1000 * 60 * 60)).toFixed(1)
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1)
        if (parseFloat(seconds) < 60) return seconds + " Sec"
        else if (parseFloat(minutes) < 60) return minutes + " Min"
        else if (parseFloat(hours) < 24) return hours + " Hrs"
        else return days + " Days"
    }

    return (
        <div className="">
            <header className="fixed top-0 w-full z-40 bg-base-300 flex items-center justify-center shadow-md h-20 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                <div className="flex py-2 pl-4 z-20 absolute left-4">
                    {!isMobile && (
                        <button className="btn btn-outline my-2" onClick={onBackButtonClicked}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 ml-[-4px] z-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={1.5}
                            >
                                <path
                                    d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                    fillRule="nonzero"
                                />
                            </svg>
                            <div className="ml-0">Back</div>
                        </button>
                    )}
                </div>
                <div
                    className="tooltip tooltip-bottom"
                    data-tip={"Your uploads, Cads that you own, account."}
                >
                    {!isMobile && (
                        <div role="tablist" className="tabs tabs-boxed my-3.5 h-14">
                            <a
                                role="tab"
                                className={
                                    "tab w-32 h-full " + (activeTab == ProfileTab.UPLOADS && "tab-active")
                                }
                                onClick={() => {
                                    setActiveTab(ProfileTab.UPLOADS)
                                }}
                            >
                                Uploads
                            </a>
                            <a
                                role="tab"
                                className={
                                    "tab w-32 h-full " + (activeTab == ProfileTab.OWNED && "tab-active")
                                }
                                onClick={() => {
                                    setActiveTab(ProfileTab.OWNED)
                                }}
                            >
                                Bought
                            </a>
                            <a
                                role="tab"
                                className={
                                    "tab w-32 h-full " + (activeTab == ProfileTab.ACCOUNT && "tab-active")
                                }
                                onClick={() => {
                                    setActiveTab(ProfileTab.ACCOUNT)
                                }}
                            >
                                Account
                            </a>
                        </div>
                    )}
                    {isMobile && (
                        <div className="flex">
                            <button
                                className="btn btn-outline h-12 mt-[15px] mr-4 r-0"
                                onClick={onBackButtonClicked}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 ml-[0px] z-0 w-[24px]"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={1.5}
                                >
                                    <path
                                        d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                        fillRule="nonzero"
                                    />
                                </svg>
                            </button>
                            <div role="tablist" className="tabs tabs-boxed my-3.5 h-12">
                                <a
                                    role="tab"
                                    className={
                                        "tab h-full " + (activeTab == ProfileTab.UPLOADS && "tab-active")
                                    }
                                    onClick={() => {
                                        setActiveTab(ProfileTab.UPLOADS)
                                    }}
                                >
                                    Uploads
                                </a>
                                <a
                                    role="tab"
                                    className={
                                        "tab h-full " + (activeTab == ProfileTab.OWNED && "tab-active")
                                    }
                                    onClick={() => {
                                        setActiveTab(ProfileTab.OWNED)
                                    }}
                                >
                                    Bought
                                </a>
                                <a
                                    role="tab"
                                    className={
                                        "tab h-full " + (activeTab == ProfileTab.ACCOUNT && "tab-active")
                                    }
                                    onClick={() => {
                                        setActiveTab(ProfileTab.ACCOUNT)
                                    }}
                                >
                                    Account
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </header>

            {activeTab == ProfileTab.UPLOADS && (
                <div>
                    {!isMobile && (
                        <div className="rounded-lg px-4 bg-base-300 shadow-xl flex flex-wrap m-3 hover:transition-all mt-24">
                            <div className="tooltip tooltip-bottom" data-tip={"Upload a model"}>
                                <button className="btn w-44 btn-outline my-4 ml-4" onClick={onClickUploadCad}>
                                    Upload model
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={"Create a bundle. Comming in next release..."}
                            >
                                <button className="btn w-44 btn-outline my-4 ml-4 mr-4" disabled>
                                    Create bundle
                                </button>
                            </div>
                        </div>
                    )}

                    <div className={"flex justify-around flex-wrap m-0 gap-8 " + (isMobile ? "pt-24" : "")}>
                        {groupings &&
                            groupings.map((grouping: Grouping, index: number) => {
                                if (isMobile) {
                                    return (
                                        <div
                                            key={grouping.id}
                                            className="bg-base-200 h-full my-4 w-full pb-10"
                                        >
                                            <div className="w-full my-4">
                                                <div className="ml-4 text-3xl font-medium text-left pt-4">
                                                    {grouping.rest ? "Ungrouped" : grouping.title}
                                                </div>
                                                <div className="ml-4 text-base font-medium text-left mt-2">
                                                    {grouping.description}
                                                </div>
                                                {grouping.url && (
                                                    <a
                                                        className="link text-lg"
                                                        href={grouping.url}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {grouping.url}
                                                    </a>
                                                )}
                                            </div>
                                            <div className="flex justify-around flex-wrap m-0 pt-4 gap-8">
                                                {grouping.cads.map((cad: Cad, index: number) => {
                                                    return (
                                                        <Item
                                                            isMobile={isMobile}
                                                            key={grouping.id + "_" + index}
                                                            cad={cad}
                                                            index={index}
                                                            onCadClicked={onCadClicked}
                                                        ></Item>
                                                    )
                                                })}
                                                <div
                                                    key={"item_placeholder_0"}
                                                    className={
                                                        "card bg-base-100 shadow-xl flex-none m-0 h-0 " +
                                                        (isMobile ? "w-80" : "w-96")
                                                    }
                                                ></div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <details
                                            key={grouping.id}
                                            className="collapse bg-base-200 collapse-arrow mx-4 w-min-96 w-full h-full my-4"
                                            open={true}
                                        >
                                            <summary className="collapse-title">
                                                <div className="text-3xl font-medium text-left">
                                                    {grouping.rest ? "Ungrouped" : grouping.title}
                                                </div>
                                                <div className="text-base font-medium text-left mt-2">
                                                    {grouping.description}
                                                </div>
                                                {grouping.url && (
                                                    <a className="link text-lg" href={grouping.url}>
                                                        {grouping.url}
                                                    </a>
                                                )}
                                            </summary>
                                            <div className="collapse-content w-full flex justify-around flex-wrap m-0 gap-8">
                                                {grouping.cads.map((cad: Cad, index: number) => {
                                                    return (
                                                        <Item
                                                            isMobile={isMobile}
                                                            key={grouping.id + "_" + index}
                                                            cad={cad}
                                                            index={index}
                                                            onCadClicked={onCadClicked}
                                                        ></Item>
                                                    )
                                                })}
                                                <div
                                                    key={"item_placeholder_0"}
                                                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                                ></div>
                                                <div
                                                    key={"item_placeholder_1"}
                                                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                                ></div>
                                                <div
                                                    key={"item_placeholder_2"}
                                                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                                ></div>
                                                <div
                                                    key={"item_placeholder_3"}
                                                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                                ></div>
                                                <div
                                                    key={"item_placeholder_4"}
                                                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                                ></div>
                                            </div>
                                        </details>
                                    )
                                }
                            })}
                    </div>
                </div>
            )}
            {activeTab == ProfileTab.OWNED && (
                <div className="flex justify-around flex-wrap m-0 gap-8 pt-24">
                    {ownedCads.map((cad: Cad, index: number) => {
                        return (
                            <Item
                                isMobile={isMobile}
                                key={"item_" + index}
                                cad={cad}
                                index={index}
                                onCadClicked={onCadClicked}
                            ></Item>
                        )
                    })}
                    <div
                        key={"item_placeholder_0"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_1"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_2"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_3"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_4"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                </div>
            )}
            {!isMobile && activeTab == ProfileTab.ACCOUNT && (
                <div className="pt-20 mx-4 flex">
                    <div className="flex flex-wrap content-start mt-4 w-1/2">
                        <div className="mr-10 pr-5">
                            {user.roles.length > 0 && (
                                <div className="my-4 w-full">
                                    <div className="text-left inline mr-4">Roles:</div>
                                    {user.roles.map((role, index) => {
                                        return (
                                            <div key={"role_tag" + index} className="badge badge-error mr-2">
                                                {role}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            <label className="form-control my-5 mt-[-10px] w-80">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Id</span>
                                </div>
                                <div className="join">
                                    <input
                                        type="text"
                                        className="input join-item focus:outline-none input-bordered w-80 input-sm text-left"
                                        value={user.id}
                                        disabled
                                    />
                                </div>
                            </label>
                            <label className="form-control my-5 mt-[-10px] w-80">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Email</span>
                                </div>
                                <div className="join">
                                    <input
                                        type="text"
                                        className="input join-item focus:outline-none input-bordered w-80 input-sm text-left"
                                        value={user.email}
                                        disabled
                                    />
                                </div>
                            </label>
                            <label className="form-control my-5 mt-[-10px] w-80">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Display Name</span>
                                    {user.displayNameRemainingCooldown > 0 && (
                                        <span className="label-text-alt">
                                            Cooldown: {msToTime(user.displayNameRemainingCooldown)}
                                        </span>
                                    )}
                                </div>
                                <div className="join">
                                    <input
                                        type="text"
                                        className="input join-item focus:outline-none input-bordered w-64 input-sm text-left"
                                        value={modifiedDisplayName}
                                        onChange={onDisplayNameValueChange}
                                        onBlur={onDisplayNameValueChange}
                                        disabled={user.displayNameRemainingCooldown > 0}
                                    />
                                    <button
                                        className="btn join-item btn-sm w-18"
                                        onClick={onDisplayNameChange}
                                        disabled={user.displayNameRemainingCooldown > 0}
                                    >
                                        Change
                                    </button>
                                </div>
                            </label>
                        </div>
                        <div className="w-80 mt-4">
                            {!user.isSeller && (
                                <div className="flex justify-around w-full">
                                    <button
                                        className="btn w-32 btn-outline margin-auto"
                                        onClick={sellerOnboardingClicked}
                                    >
                                        Become a seller
                                    </button>
                                </div>
                            )}
                            {user.isSeller && user.sellerAccountId && (
                                <label className="form-control my-5 w-80 mt-[-25px]">
                                    <div className="label mb-[-5px]">
                                        <span className="label-text">Invoicing Details</span>
                                        {(!user.invoicingData ||
                                            (user.invoicingData &&
                                                (!user.invoicingData.address ||
                                                    !user.invoicingData.name))) && (
                                            <div className="badge badge-outline">Provide Invoicing data</div>
                                        )}
                                        {user.invoicingData &&
                                            user.invoicingData.address &&
                                            user.invoicingData.name && (
                                                <div className="badge badge-accent">Verified seller</div>
                                            )}
                                    </div>
                                    <div className="text-xs m-2">
                                        By the 10th day of each month we will use this information to send you
                                        an aggregated invoice for all the fees that we took when you made a
                                        trade.
                                    </div>
                                    <input
                                        type="text"
                                        className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                        placeholder="Name"
                                        value={invoicingName}
                                        onChange={onInvocingNameValueChange}
                                        onBlur={onInvocingNameValueChange}
                                    />
                                    <input
                                        type="text"
                                        className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                        placeholder="Tax Id (optional)"
                                        value={invoicingTaxId}
                                        onChange={onInvoicingTaxIdChange}
                                        onBlur={onInvoicingTaxIdChange}
                                    />
                                    <input
                                        type="text"
                                        className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                        placeholder="Address"
                                        value={invoicingAddress}
                                        onChange={onInvoicingAddressChange}
                                        onBlur={onInvoicingAddressChange}
                                    />
                                    <div className="flex justify-between w-full">
                                        <button
                                            className="btn join-item btn-sm w-32"
                                            onClick={() =>
                                                window.open("https://dashboard.stripe.com/login", "_blank")
                                            }
                                        >
                                            Stripe account
                                        </button>
                                        <button
                                            className="btn join-item btn-sm w-32"
                                            onClick={onSetInvoicingData}
                                        >
                                            Set
                                        </button>
                                    </div>
                                </label>
                            )}
                        </div>
                        <div className="w-full mt-8">
                            <label className="form-control mt-[-10px] w-full">
                                <div className="label mb-[-15px]">
                                    <span className="label-text">Youtube URL</span>
                                </div>
                                <input
                                    type="text"
                                    className="input my-2 mb-8 join-item focus:outline-none input-bordered input-sm w-96 text-left mb-2"
                                    placeholder="Youtube channel Url"
                                    value={profileYoutubeUrl}
                                    onChange={onProfileYoutubeUrlChange}
                                    onBlur={onProfileYoutubeUrlChange}
                                />
                            </label>
                            <label className="form-control mt-[-10px] w-full">
                                <div className="label mb-[-0px]">
                                    <span className="label-text">Profile description</span>
                                </div>
                                <textarea
                                    className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                    placeholder="Profile Description"
                                    value={profileDescription}
                                    onChange={onProfileDescriptionChange}
                                    onBlur={onProfileDescriptionChange}
                                ></textarea>
                                <div className="flex justify-between">
                                    <button
                                        className="btn btn-sm w-32"
                                        onClick={() =>
                                            window.open(
                                                `/user/${encodeURIComponent(user.displayName)}`,
                                                "_blank",
                                            )
                                        }
                                    >
                                        Open profile
                                    </button>
                                    <button className="btn join-item btn-sm w-32" onClick={onSetProfileData}>
                                        Set
                                    </button>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="pt-8 pl-10 pr-4 w-6/12">
                        <div className="">Transaction History:</div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Models</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user &&
                                    user.transactions.map((transaction: Transaction, index: number) => {
                                        return (
                                            <tr className="" key={"transaction_" + index}>
                                                <th>{index + 1}</th>
                                                <th>{formatDate(transaction.date)}</th>
                                                <th>{transactionTypeToDescription(transaction.type)}</th>
                                                <th>
                                                    {transaction.cadIds.map((cadId, index2) => {
                                                        return (
                                                            <a
                                                                key={"transaction_cad_item" + index2}
                                                                className="link"
                                                                href={`/model/${cadId}`}
                                                            >
                                                                {"Model #" + (index2 + 1)}
                                                            </a>
                                                        )
                                                    })}
                                                </th>
                                                <th>{transactionStatusDescription(transaction.status)}</th>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {isMobile && activeTab == ProfileTab.ACCOUNT && (
                <div className="pt-20 mx-4 flex flex-wrap">
                    <div className="flex flex-wrap content-start mt-4 justify-around">
                        {user.roles.length > 0 && (
                            <div className="my-4 w-full">
                                <div className="text-left inline mr-4">Roles:</div>
                                {user.roles.map((role, index) => {
                                    return (
                                        <div key={"role_tag" + index} className="badge badge-error mr-2">
                                            {role}
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        <label className="form-control my-5 mt-[-10px] w-80">
                            <div className="label mb-[-5px]">
                                <span className="label-text">Email</span>
                            </div>
                            <div className="join">
                                <input
                                    type="text"
                                    className="input join-item focus:outline-none input-bordered input-sm w-64 text-left"
                                    value={user.email}
                                    disabled
                                />
                            </div>
                        </label>
                        <label className="form-control my-5 mt-[-10px]">
                            <div className="label mb-[-5px]">
                                <span className="label-text">Display Name</span>
                                {user.displayNameRemainingCooldown > 0 && (
                                    <span className="label-text-alt">
                                        Cooldown: {msToTime(user.displayNameRemainingCooldown)}
                                    </span>
                                )}
                            </div>
                            <div className="join">
                                <input
                                    type="text"
                                    className="input join-item focus:outline-none input-bordered input-sm w-60 text-left"
                                    value={modifiedDisplayName}
                                    onChange={onDisplayNameValueChange}
                                    onBlur={onDisplayNameValueChange}
                                    disabled={user.displayNameRemainingCooldown > 0}
                                />
                                <button
                                    className="btn join-item btn-sm"
                                    onClick={onDisplayNameChange}
                                    disabled={user.displayNameRemainingCooldown > 0}
                                >
                                    Change
                                </button>
                            </div>
                        </label>
                        <div>
                            <label className="form-control mt-[-10px]">
                                <div className="label mb-[-15px]">
                                    <span className="label-text">Youtube URL</span>
                                </div>
                                <input
                                    type="text"
                                    className="input my-2 mb-8 join-item focus:outline-none input-bordered input-sm w-80 text-left mb-2"
                                    placeholder="Youtube channel Url"
                                    value={profileYoutubeUrl}
                                    onChange={onProfileYoutubeUrlChange}
                                    onBlur={onProfileYoutubeUrlChange}
                                />
                            </label>
                            <label className="form-control mt-[-10px] w-80">
                                <div className="label mb-[-0px]">
                                    <span className="label-text">Profile description</span>
                                </div>
                                <textarea
                                    className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                    placeholder="Profile Description"
                                    value={profileDescription}
                                    onChange={onProfileDescriptionChange}
                                    onBlur={onProfileDescriptionChange}
                                ></textarea>
                                <div className="flex justify-between">
                                    <button
                                        className="btn btn-sm w-32"
                                        onClick={() =>
                                            window.open(
                                                `/user/${encodeURIComponent(user.displayName)}`,
                                                "_blank",
                                            )
                                        }
                                    >
                                        Open profile
                                    </button>
                                    <button className="btn btn-sm w-32" onClick={onSetProfileData}>
                                        Set
                                    </button>
                                </div>
                            </label>
                        </div>
                        {!user.isSeller && (
                            <div className="flex w-full mt-10 justify-around">
                                <button className="btn btn-outline btn-sm" onClick={sellerOnboardingClicked}>
                                    Become a seller
                                </button>
                            </div>
                        )}
                        {user.isSeller && user.sellerAccountId && (
                            <label className="form-control my-5 w-80">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Invoicing Details</span>
                                    {(!user.invoicingData ||
                                        (user.invoicingData &&
                                            (!user.invoicingData.address || !user.invoicingData.name))) && (
                                        <div className="badge badge-outline">Provide Invoicing data</div>
                                    )}
                                    {user.invoicingData &&
                                        user.invoicingData.address &&
                                        user.invoicingData.name && (
                                            <div className="badge badge-accent">Verified seller</div>
                                        )}
                                </div>
                                <div className="text-xs m-2">
                                    By the 10th day of each month we will use this information to send you an
                                    aggregated invoice for all the fees that we took when you made a trade.
                                </div>
                                <input
                                    type="text"
                                    className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                    placeholder="Name"
                                    value={invoicingName}
                                    onChange={onInvocingNameValueChange}
                                    onBlur={onInvocingNameValueChange}
                                />
                                <input
                                    type="text"
                                    className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                    placeholder="Tax Id"
                                    value={invoicingTaxId}
                                    onChange={onInvoicingTaxIdChange}
                                    onBlur={onInvoicingTaxIdChange}
                                />
                                <input
                                    type="text"
                                    className="input my-2 join-item focus:outline-none input-bordered input-sm w-80 text-left"
                                    placeholder="Address"
                                    value={invoicingAddress}
                                    onChange={onInvoicingAddressChange}
                                    onBlur={onInvoicingAddressChange}
                                />
                                <div className="flex justify-between">
                                    <button
                                        className="btn join-item btn-sm w-32"
                                        onClick={() =>
                                            window.open("https://dashboard.stripe.com/login", "_blank")
                                        }
                                    >
                                        Stripe account
                                    </button>
                                    <button
                                        className="btn join-item btn-sm w-32"
                                        onClick={onSetInvoicingData}
                                    >
                                        Set
                                    </button>
                                </div>
                            </label>
                        )}
                    </div>
                    <div className="pt-8 mb-8 w-full py-4">
                        <div>Transaction History:</div>
                        <table className="table w-full table-xs">
                            <thead>
                                <tr>
                                    <th className=""></th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Models</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user &&
                                    user.transactions.map((transaction: Transaction, index: number) => {
                                        return (
                                            <tr key={"transaction_" + index}>
                                                <th className="">{index + 1}</th>
                                                <th className="">{formatDate(transaction.date)}</th>
                                                <th className="">
                                                    {transactionTypeToDescription(transaction.type)}
                                                </th>
                                                <th className="">
                                                    {transaction.cadIds.map((cadId, index2) => {
                                                        return (
                                                            <a
                                                                key={"transaction_cad_item" + index2}
                                                                className="link"
                                                                href={`/model/${cadId}`}
                                                            >
                                                                {"#" + (index2 + 1)}
                                                            </a>
                                                        )
                                                    })}
                                                </th>
                                                <th className="">
                                                    {transactionStatusDescription(transaction.status)}
                                                </th>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}
