import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"

import { PropertyItem, ModifiedProperty } from "./PropertyItem"
import { useApi } from "../model/api"

type PropertiesProps = {
    isMobile: boolean
    properties: ModifiedProperty[]
    handleRender: () => void
    handlePropertyChange: (property: ModifiedProperty, newValue: string) => void
    handleDefault: () => void
    hidden: boolean
    setHidden: any
    canRender: boolean
}

function Properties(props: PropertiesProps) {
    const {
        isMobile,
        properties,
        handleRender,
        handleDefault,
        handlePropertyChange,
        hidden,
        setHidden,
        canRender,
    } = props
    const { user } = useApi()
    //

    if (isMobile) {
        return (
            <div className="w-full pt-24">
                <div className="bg-base-300/90 pt-5 overflow-y-auto">
                    {properties.length == 0 && (
                        <div className="font-bold text-lg w-full text-center pb-2">No properties</div>
                    )}
                    {properties.length > 0 && (
                        <div>
                            {properties.map((property, index) => {
                                return (
                                    <div key={"propertyItem_" + property.cellAddress}>
                                        <PropertyItem
                                            property={property}
                                            onPropertyChange={handlePropertyChange}
                                        ></PropertyItem>
                                        {index < properties.length - 1 && (
                                            <div className="divider pl-5 py-0 my-0 w-11/12"></div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                <div className="pb-4 pt-2 mb-0 text-right flex justify-center bg-base-300/90 rounded-none">
                    {properties.length > 0 && (
                        <div className="join join-horizontal mt-2">
                            <button
                                className="join-item btn btn-accent w-32"
                                onClick={handleRender}
                                disabled={!canRender}
                            >
                                Render
                            </button>
                            <button
                                className="join-item btn btn-error w-32"
                                onClick={handleDefault}
                                disabled={!canRender}
                            >
                                Set defaults
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div className="absolute w-full pt-20 left-0 z-100">
                <div
                    className={
                        "transition-transform duration-300" +
                        (hidden ? " translate-x-[-95%] hover:translate-x-[-90%] hover:transition-scale" : "")
                    }
                    onClick={hidden ? setHidden : () => {}}
                >
                    <div
                        className="text-right flex mt-8 justify-start bg-base-300/90 rounded-t-2xl"
                        onClick={setHidden}
                    >
                        <div className="font-bold text-3xl ml-4 mt-[-28px] text-left">
                            Properties
                            <button
                                className="btn btn-ghost text-base ml-[-10px] hover:bg-transparent"
                                onClick={setHidden}
                            >
                                <svg
                                    className="h-6 w-6 translate-y-1"
                                    viewBox="0 0 48 48"
                                    stroke="none"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.957,33.214L7.171,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.953-5.159-7.957-9.214
                                            L40.829,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.953,29.159,11.957,33.214z M16,24
                                            c0-4.418,3.582-8,8-8c1.483,0,2.867,0.411,4.058,1.114l-3.035,3.035C24.694,20.062,24.356,20,24,20c-2.206,0-4,1.794-4,4
                                            c0,0.356,0.062,0.694,0.149,1.023l-3.035,3.035C16.411,26.867,16,25.483,16,24z M32,24c0,4.418-3.582,8-8,8
                                            c-1.483,0-2.867-0.411-4.058-1.114l3.035-3.035C23.306,27.938,23.644,28,24,28c2.206,0,4-1.794,4-4
                                            c0-0.356-0.062-0.694-0.149-1.023l3.035-3.035C31.589,21.133,32,22.517,32,24z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="bg-base-300/90 pt-5 overflow-y-auto max-h-[65vh] drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                        {properties.length == 0 && (
                            <div className="font-bold w-full text-center text-lg pb-2">No properties</div>
                        )}
                        {properties.length > 0 && (
                            <div>
                                {properties.map((property, index) => {
                                    return (
                                        <div key={"propertyItem_" + property.cellAddress}>
                                            <PropertyItem
                                                property={property}
                                                onPropertyChange={handlePropertyChange}
                                            ></PropertyItem>
                                            {index < properties.length - 1 && (
                                                <div className="divider pl-5 py-0 my-0 w-11/12"></div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <div className="pb-4 pt-2 mb-3 text-right flex justify-center bg-base-300/90 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)] rounded-b-2xl">
                        {properties.length > 0 && (
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={
                                    !user
                                        ? "Login to use parametric functionality"
                                        : "Render models, reset parameters"
                                }
                            >
                                <div className="join join-vertical lg:join-horizontal mt-2">
                                    <button
                                        className="join-item btn btn-accent w-44"
                                        onClick={handleRender}
                                        disabled={!canRender}
                                    >
                                        Render
                                    </button>
                                    <button
                                        className="join-item btn btn-error w-44"
                                        onClick={handleDefault}
                                        disabled={!canRender}
                                    >
                                        Set defaults
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Properties
