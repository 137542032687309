import React, { useEffect, useRef } from "react"
import { useState, useCallback } from "react"
import "./App.css"

import { useMediaQuery } from "react-responsive"

import { Details, DetailsMode } from "../details/Details"
import List from "../list/List"
import Upload from "../upload/Upload"
import { Profile, ProfileTab } from "../Profile/Profile"

import { SettingsContextProvider } from "../model/settings"

import {
    useApi,
    Cad,
    PriceFilter,
    User,
    Grouping,
    Profile as ProfileType,
    Sort,
    ParametricFilter,
} from "../model/api"
import Privacy from "../legal/Privacy"
import Terms from "../legal/Terms"
import PublicUser from "../Profile/PublicUser"

import Cookies from "../legal/Cookies"
import Refund from "../legal/Refund"

function getQueryVariable(key: string): string | null {
    var query = window.location.search.substring(1)
    var vars = query.split("&")
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=")
        if (pair[0] == key) {
            return pair[1]
        }
    }
    return null
}

function getPathVariable(): string | null {
    var path = window.location.pathname
    var paths = path.split("/")
    return paths[paths.length - 1]
}

enum NavigationMode {
    LIST = "list",
    DETAILS = "model",
    UPLOAD = "upload",
    PROFILE = "profile",
    USER = "user",
    REGISTER_SELLER = "registerSeller",
    TERMS = "terms",
    PRIVACY = "privacy",
    COOKIES = "cookies",
    REFUND = "refund",
    PURCHASE = "purchase",
    TUTORIAL = "tutorial",
}

type HistoryEntry = {
    url: string
    mode: NavigationMode
    params?: any
}

const CAD_PAGE_SIZE = 25

function App() {
    const { user, getCad, getCadList, getUserGroupings } = useApi()
    const [cads, setCads] = useState<Cad[]>([])
    const [selectedCad, setSelectedCad] = useState<Cad | null>(null)
    const [selectedUserProfile, setSelectedUserProfile] = useState<ProfileType | null>(null)
    const [urlCadId, setUrlCadId] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>(null)
    const [keyword, setKeyword] = useState<string>("")
    const [priceFilter, setPriceFilter] = useState<PriceFilter>(PriceFilter.ALL)
    const [sortFilter, setSortFilter] = useState<Sort>(Sort.NEWEST)
    const [parametricFilter, setParametricFilter] = useState<ParametricFilter>(ParametricFilter.ALL)

    const [navigationMode, setNavigationMode] = useState<NavigationMode>(NavigationMode.LIST)

    const listScrollPos = useRef<number>(0)
    const [savedScrollPosition, setSavedScrollPosition] = useState<number>(0)

    const [historyStack, setHistoryStack] = useState<HistoryEntry[]>([])

    const [activeTab, setActiveTab] = useState<ProfileTab>(ProfileTab.UPLOADS)

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" })

    const setCurrentActiveTab = useCallback((tab: ProfileTab) => {
        setActiveTab(tab)
        const newUrl = new URL(window.location.toString())
        newUrl.pathname = "profile" + "/" + tab
        // eslint-disable-next-line no-restricted-globals
        history.pushState({}, "", newUrl)
    }, [])

    const navigateForward = useCallback(
        (newMode: NavigationMode, url: string, params?: any) => {
            const newHistoryStack = structuredClone(historyStack)

            const currentUrl = new URL(window.location.toString())
            newHistoryStack.push({
                mode: navigationMode,
                url: currentUrl.pathname,
                params,
            })
            setHistoryStack(newHistoryStack)

            const newUrl = new URL(window.location.toString())
            newUrl.pathname = url
            // eslint-disable-next-line no-restricted-globals
            history.pushState({}, "", newUrl)
            setNavigationMode(newMode)
        },
        [historyStack, navigationMode],
    )

    const navigateBackward = useCallback(() => {
        const newHistoryStack = structuredClone(historyStack)

        const previousEntry =
            newHistoryStack.length > 0
                ? newHistoryStack[newHistoryStack.length - 1]
                : { url: "/", mode: NavigationMode.LIST }

        newHistoryStack.pop()
        setHistoryStack(newHistoryStack)

        const newUrl = new URL(window.location.toString())
        newUrl.pathname = previousEntry.url
        newUrl.hash = ""

        // eslint-disable-next-line no-restricted-globals
        history.pushState({}, "", newUrl)
        setNavigationMode(previousEntry.mode)
    }, [historyStack])

    useEffect(() => {
        const pathname = window.location.pathname
        const paths = pathname.split("/").filter((path) => path.length > 0)
        switch (paths[0]) {
            case "cookies":
                setNavigationMode(NavigationMode.COOKIES)
                break
            case "terms":
                setNavigationMode(NavigationMode.TERMS)
                break
            case "privacy":
                setNavigationMode(NavigationMode.PRIVACY)
                break
            case "refund":
                setNavigationMode(NavigationMode.REFUND)
                break
            case "profile":
                setNavigationMode(NavigationMode.PROFILE)
                break
            case "upload":
                setNavigationMode(NavigationMode.UPLOAD)
                break
            case "registerSeller":
                setNavigationMode(NavigationMode.LIST)
                break
            case "user":
                setNavigationMode(NavigationMode.USER)
                break
            case "model":
                setNavigationMode(NavigationMode.DETAILS)
                break
            case "purchase":
                setNavigationMode(NavigationMode.PURCHASE)
                break
            case "tutorial":
                setNavigationMode(NavigationMode.TUTORIAL)
                break
            default:
                setNavigationMode(NavigationMode.LIST)
                break
        }
    }, [])

    useEffect(() => {
        loadContent(false)
    }, [
        getCadList,
        getCad,
        urlCadId,
        keyword,
        priceFilter,
        sortFilter,
        parametricFilter,
        navigationMode,
        navigateBackward,
        navigateForward,
    ])

    const showErrorMessage = useCallback(
        (message: string) => {
            setErrorMessage(message)
            setTimeout(() => {
                setErrorMessage(null)
            }, 3000)
        },
        [setErrorMessage],
    )

    const showSuccessMessage = useCallback(
        (message: string) => {
            setSuccessMessage(message)
            setTimeout(() => {
                setSuccessMessage(null)
            }, 3000)
        },
        [setSuccessMessage],
    )

    const loadContent = useCallback(
        async (forceFetch: boolean) => {
            switch (navigationMode) {
                case NavigationMode.TUTORIAL:
                    const tutorialMode = getQueryVariable("mode")

                    break
                case NavigationMode.PURCHASE:
                    const cadIds = getQueryVariable("cadIds")

                    break
                case NavigationMode.REGISTER_SELLER:
                    const status = getPathVariable()
                    if (status == "done") {
                        navigateForward(NavigationMode.PROFILE, "/profile")
                    } else if (status == "refresh") {
                        navigateForward(NavigationMode.PROFILE, "/profile")
                    }
                    break
                case NavigationMode.USER:
                    const userDisplayName = getPathVariable()
                    if (userDisplayName != null) {
                        const profile = await getUserGroupings(userDisplayName)
                        if (!profile) {
                            showErrorMessage("User not found")
                            navigateBackward()
                        } else {
                            setSelectedUserProfile(profile)
                        }
                    }
                    break
                case NavigationMode.DETAILS:
                    const cadId = getPathVariable()
                    if (cadId != null) {
                        var cad
                        if (!forceFetch) {
                            cad = cads.find((cad) => cad.id == cadId)
                        }
                        if (!cad) {
                            cad = await getCad(cadId)
                        }
                        if (cad == undefined) {
                            navigateBackward()
                            showErrorMessage("Model not found")
                        } else {
                            setSelectedCad(cad)
                        }
                    }
                    break
                case NavigationMode.PROFILE:
                    const path = getPathVariable()
                    if (
                        path == ProfileTab.ACCOUNT ||
                        path == ProfileTab.OWNED ||
                        path == ProfileTab.UPLOADS
                    ) {
                        setActiveTab(path)
                    }
                    break

                case NavigationMode.LIST:
                default:
                    const queryKeyword = getQueryVariable("keyword")
                    const queryPriceFilter = getQueryVariable("price")
                    const querySortFilter = getQueryVariable("sort")
                    const queryParametricFilter = getQueryVariable("parametric")
                    var list

                    var newPriceFilter = PriceFilter.ALL
                    if (
                        queryPriceFilter == PriceFilter.ALL ||
                        queryPriceFilter == PriceFilter.FREE ||
                        queryPriceFilter == PriceFilter.PAID
                    ) {
                        newPriceFilter = queryPriceFilter
                    }

                    var newSortFilter = Sort.NEWEST
                    if (
                        querySortFilter == Sort.CLAIMS ||
                        querySortFilter == Sort.RATING ||
                        querySortFilter == Sort.NEWEST ||
                        querySortFilter == Sort.POPULAR
                    ) {
                        newSortFilter = querySortFilter
                    }

                    var newParametricFilter = ParametricFilter.ALL
                    if (
                        queryParametricFilter == ParametricFilter.PARAMETRIC ||
                        queryParametricFilter == ParametricFilter.ALL
                    ) {
                        newParametricFilter = queryParametricFilter
                    }

                    setPriceFilter(newPriceFilter)
                    setSortFilter(newSortFilter)
                    setParametricFilter(newParametricFilter)

                    var page = 0
                    if (queryKeyword && queryKeyword.length >= 3) {
                        setSavedScrollPosition(0)

                        list = await getCadList(
                            {
                                keyword: queryKeyword,
                                price: newPriceFilter,
                                sort: newSortFilter,
                                parametric: newParametricFilter,
                            },
                            page,
                        )
                        if (keyword != queryKeyword) {
                            setKeyword(queryKeyword)
                        }
                    } else {
                        if (queryKeyword == null || queryKeyword.length == 0) {
                            setKeyword("")
                            const newUrl = new URL(window.location.toString())
                            newUrl.searchParams.delete("keyword")
                            // eslint-disable-next-line no-restricted-globals
                            history.pushState({}, "", newUrl)
                        }
                        setKeyword("")
                        const page = 0
                        list = await getCadList(
                            { price: newPriceFilter, sort: newSortFilter, parametric: newParametricFilter },
                            page,
                        )
                        setSavedScrollPosition(0)
                    }
                    setCads(list)
                    break
            }
        },
        [
            getCadList,
            getCad,
            urlCadId,
            keyword,
            priceFilter,
            sortFilter,
            parametricFilter,
            navigationMode,
            navigateBackward,
            navigateForward,
        ],
    )

    const reloadContent = useCallback(() => {
        loadContent(true)
    }, [loadContent])

    const cadListLoadNextPage = useCallback(async () => {
        if (navigationMode != NavigationMode.LIST) {
            return false
        }

        var page = Math.floor(cads.length / CAD_PAGE_SIZE)
        const fetchedCads = await getCadList({ price: priceFilter, sort: sortFilter }, page)

        const oldListIds: string[] = cads.map((cad) => cad.id)
        const newItems = fetchedCads.filter((cad) => !oldListIds.includes(cad.id))
        const mergedList = structuredClone(cads).concat(newItems)
        setSavedScrollPosition(listScrollPos.current)
        setCads(mergedList)
        return newItems.length > 0
    }, [loadContent, navigationMode, cads])

    const onCadClicked = useCallback(
        (cad: Cad, index: number, newTab: boolean) => {
            if (newTab) {
                const newUrl = new URL(`model/${cad.id}`, window.location.origin)
                window.open(newUrl, "_blank")
            } else {
                navigateForward(NavigationMode.DETAILS, `model/${cad.id}`)
                setSavedScrollPosition(listScrollPos.current)
                setSelectedCad(cad)
            }
        },
        [cads, navigateForward],
    )

    const onPublicProfileBackButtonClicked = useCallback(() => {
        setSelectedUserProfile(null)
        navigateBackward()
    }, [selectedCad, navigateBackward])

    const onBackButtonClicked = useCallback(() => {
        setSelectedCad(null)
        navigateBackward()
    }, [selectedCad, navigateBackward])

    const onUploadBackButtonClicked = useCallback(() => {
        navigateBackward()
    }, [selectedCad, navigateBackward])

    const onUploadButtonClicked = useCallback(() => {
        navigateForward(NavigationMode.UPLOAD, `/upload`)
    }, [selectedCad, navigateForward])

    const onProfileBackButtonClicked = useCallback(() => {
        navigateBackward()
    }, [selectedCad, navigateBackward])

    const onProfileButtonClicked = useCallback(() => {
        navigateForward(NavigationMode.PROFILE, `/profile/${ProfileTab.UPLOADS}`)
    }, [selectedCad, navigateForward])

    const onSearchUpdated = useCallback(
        (
            keyword: string,
            priceFilterType: PriceFilter,
            parametricFilter: ParametricFilter,
            sortFilter: Sort,
        ) => {
            setSelectedCad(null)
            const newUrl = new URL(window.location.toString())
            newUrl.searchParams.delete("id")

            if (keyword == null || keyword.length == 0) {
                newUrl.searchParams.delete("keyword")
            } else {
                newUrl.searchParams.set("keyword", keyword)
            }

            if (priceFilterType == PriceFilter.ALL) {
                newUrl.searchParams.delete("price")
            } else {
                newUrl.searchParams.set("price", priceFilterType)
            }

            if (sortFilter == Sort.NEWEST) {
                newUrl.searchParams.delete("sort")
            } else {
                newUrl.searchParams.set("sort", sortFilter)
            }

            if (parametricFilter == ParametricFilter.ALL) {
                newUrl.searchParams.delete("parametric")
            } else {
                newUrl.searchParams.set("parametric", parametricFilter)
            }
            // eslint-disable-next-line no-restricted-globals
            history.pushState({}, "", newUrl)
            setKeyword(keyword)
            setPriceFilter(priceFilterType)
            setSortFilter(sortFilter)
            setParametricFilter(parametricFilter)
        },
        [selectedCad, cads],
    )

    return (
        <div className="w-full">
            {errorMessage && !isMobile && (
                <div role="alert" className="alert alert-error fixed right-8 bottom-8 z-50 flex rounded w-80">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <div>{errorMessage}</div>
                </div>
            )}
            {successMessage && !isMobile && (
                <div
                    role="alert"
                    className="alert alert-success bg-accent fixed right-8 bottom-8 z-50 flex rounded w-80"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 shrink-0 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <div>{successMessage}</div>
                </div>
            )}
            {errorMessage && isMobile && (
                <div role="alert" className="alert alert-error fixed right-2 bottom-2 z-50 flex rounded w-64">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <div>{errorMessage}</div>
                </div>
            )}
            {successMessage && isMobile && (
                <div
                    role="alert"
                    className="alert alert-success bg-accent fixed right-2 bottom-2 z-50 flex rounded w-64"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 shrink-0 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <div>{successMessage}</div>
                </div>
            )}

            <SettingsContextProvider>
                {navigationMode == NavigationMode.USER && selectedUserProfile && (
                    <PublicUser
                        isMobile={isMobile}
                        profile={selectedUserProfile}
                        onBackButtonClicked={onPublicProfileBackButtonClicked}
                        onCadClicked={onCadClicked}
                    ></PublicUser>
                )}
                {navigationMode == NavigationMode.REFUND && <Refund></Refund>}
                {navigationMode == NavigationMode.COOKIES && <Cookies></Cookies>}
                {navigationMode == NavigationMode.PRIVACY && <Privacy></Privacy>}
                {navigationMode == NavigationMode.TERMS && <Terms></Terms>}
                {navigationMode == NavigationMode.UPLOAD && (
                    <Upload
                        isMobile={isMobile}
                        backButtonPressed={onUploadBackButtonClicked}
                        showSuccessMessage={showSuccessMessage}
                        showErrorMessage={showErrorMessage}
                    ></Upload>
                )}
                {navigationMode == NavigationMode.LIST && (
                    <div className="flex flex-col min-h-screen">
                        <List
                            isMobile={isMobile}
                            cadList={cads}
                            onCadClicked={onCadClicked}
                            onSearchUpdated={onSearchUpdated}
                            onUploadButtonClicked={onUploadButtonClicked}
                            onProfileButtonClicked={onProfileButtonClicked}
                            searchValue={keyword}
                            scrollPositionRef={listScrollPos}
                            scrollPos={savedScrollPosition}
                            priceFilter={priceFilter}
                            parametricFilter={parametricFilter}
                            sort={sortFilter}
                            loadNextPage={cadListLoadNextPage}
                        ></List>
                        <footer className="footer py-4 bg-base-300">
                            <aside className="w-full">
                                <div className="flex justify-evenly flex-wrap gap-y-4 w-full">
                                    <div className="ml-4 mt-1 text-balance text-center h-full flex items-center">
                                        <div className="">
                                            Copyright © 2024 - All right reserved by Konrad Faltyn
                                        </div>
                                    </div>
                                    <div className="text-right flex text-center">
                                        <a className="link text-base mr-4" href="/terms">
                                            Terms and Conditions
                                        </a>
                                        <a className="link text-base mr-4" href="/privacy">
                                            Privacy Policy
                                        </a>
                                        <a className="link text-base mr-4" href="/cookies">
                                            Cookies
                                        </a>
                                        <a className="link text-base mr-4" href="/refund">
                                            Refunds
                                        </a>
                                    </div>
                                    <div className="">
                                        <a
                                            href={"https://discord.gg/sZVggxuyhM"}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                                                <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </aside>
                        </footer>
                    </div>
                )}
                {navigationMode == NavigationMode.DETAILS && selectedCad && (
                    <Details
                        isMobile={isMobile}
                        cad={selectedCad}
                        onBackButtonClicked={onBackButtonClicked}
                        mode={DetailsMode.PRESENT}
                        type={selectedCad.type}
                        reload={reloadContent}
                        showSuccessMessage={showSuccessMessage}
                        showErrorMessage={showErrorMessage}
                    ></Details>
                )}
                {navigationMode == NavigationMode.PROFILE && user && (
                    <Profile
                        isMobile={isMobile}
                        user={user}
                        onBackButtonClicked={onProfileBackButtonClicked}
                        onCadClicked={onCadClicked}
                        onClickUploadCad={onUploadButtonClicked}
                        activeTab={activeTab}
                        setActiveTab={setCurrentActiveTab}
                        showSuccessMessage={showSuccessMessage}
                        showErrorMessage={showErrorMessage}
                    ></Profile>
                )}
            </SettingsContextProvider>
        </div>
    )
}

export default App
