import React from "react"
import { useState, useCallback, createRef } from "react"
import "./List.css"
import ReactFreezeframe from "../forked/freezeframe/ReactFreezeframe"

import { Cad, useApi } from "../model/api"

type ItemProps = {
    cad: Cad
    index: number
    onCadClicked: (cad: Cad, index: number, newTab: boolean) => void
    isMobile: boolean
}

function Item(props: ItemProps) {
    const { REACT_APP_API_URL } = process.env
    const { cad, onCadClicked, index, isMobile } = props

    const { user } = useApi()

    const { isCadOwned } = useApi()
    const freezeRef = createRef<any>()

    const onMouseEnter = () => {
        if (!freezeRef.current) {
            return
        }
        const parent = document.getElementById(cad.id)
        if (!parent) {
            return
        }

        const event = new Event("mouseenter-mod")
        for (const child1 of parent.children) {
            for (const child2 of child1.children) {
                for (const child3 of child2.children) {
                    for (const child4 of child3.children) {
                        child4.dispatchEvent(event)
                    }
                }
            }
        }
    }

    const onMouseLeave = () => {
        if (!freezeRef.current) {
            return
        }
        freezeRef.current.$freezeframe.stop()
        const parent = document.getElementById(cad.id)
        if (!parent) {
            return
        }
        const event = new Event("mouseleave-mod")
        for (const child1 of parent.children) {
            for (const child2 of child1.children) {
                for (const child3 of child2.children) {
                    for (const child4 of child3.children) {
                        child4.dispatchEvent(event)
                    }
                }
            }
        }
    }

    const cadStatus = () => {
        if (cad.price == null || cad.price == 0) {
            return "Free"
        } else if (isCadOwned(cad.id)) {
            return "Bought"
        } else if (user && cad.userId == user.id) {
            return "Owned"
        } else {
            return `$${cad.price}`
        }
    }

    const onCadClick = useCallback(
        (event: any) => {
            if (
                event.ctrlKey ||
                event.shiftKey ||
                event.metaKey || // apple
                (event.button && event.button == 1) // middle click, >IE9 + everyone else
            ) {
                onCadClicked(cad, index, true)
            } else {
                onCadClicked(cad, index, false)
            }
            event.preventDefault()
        },
        [cad],
    )

    return (
        <div
            className={
                "card card-compact bg-base-300 rounded shadow-xl flex-none hover:scale-105 hover:transition-all rounded-none " +
                (isMobile ? "w-80" : "w-96")
            }
            onClick={onCadClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <figure id={cad.id} className="h-[15rem]">
                <video loop muted autoPlay>
                    <source
                        src={`${REACT_APP_API_URL || "http://localhost"}/${cad.thumbnailUrl}`}
                        type="video/mp4"
                    ></source>
                </video>
            </figure>
            <div className="card-body">
                <div
                    className={
                        "mt-[-57px] mr-[-10px] z-10 text-right " + (cad.ratingSum > 0 ? "" : "opacity-40")
                    }
                >
                    <div className="rating mb-4 bg-base-100 p-1 rounded-xl">
                        {[1, 2, 3, 4, 5].map((value, index, array) => {
                            return (
                                <input
                                    readOnly
                                    key={"rating_key_" + value + "__" + index}
                                    type="radio"
                                    name={cad.id + "rating-" + array.length}
                                    className={
                                        "mb-1 mask mask-star-2 " +
                                        (cad.ratingSum > 0 ? "bg-accent" : "bg-neutral")
                                    }
                                    checked={
                                        cad.ratingNumber > 0 && cad.ratingSum / cad.ratingNumber >= value
                                    }
                                />
                            )
                        })}
                    </div>
                </div>
                <h2 className="card-title text-2xl mb-2 mt-[-8px]">
                    {cad.title}
                    {!isMobile && cad.properties.length > 0 && (
                        <div className="badge badge-outline badge-accent">Parametric</div>
                    )}
                    {cad.reportsCount > 0 && <div className="badge badge-outline badge-error">Claim</div>}
                </h2>
                {isMobile && cad.properties.length > 0 && (
                    <div className="badge badge-outline badge-accent">Parametric</div>
                )}
                {isMobile && (
                    <p className="text-left text-m mb-3 h-16">
                        {cad.description.replace(/(.{126})..+/, "$1\u2026")}
                    </p>
                )}
                {!isMobile && (
                    <p className="text-left text-m mb-3 h-16">
                        {cad.description.replace(/(.{160})..+/, "$1\u2026")}
                    </p>
                )}
                <div className="card-actions justify-start">
                    {cad.tags.map((tag: string, index: number) => {
                        return (
                            <div key={"item_tag_" + index} className="badge badge-outline">
                                {tag}
                            </div>
                        )
                    })}
                </div>
                <div className="container flex text-left mt-2">
                    <p className="object-right-top">
                        {cad.author} - {new Date(cad.date).toLocaleDateString()}
                    </p>
                    <div className="badge badge-lg w-20">{cadStatus()}</div>
                </div>
            </div>
        </div>
    )
}

export default Item
