import React, { useCallback, useEffect, useState, useContext, useRef } from "react"
import "./Details.css"

import { SceneEntry } from "../model/api"
import { Rotation } from "./Details"

type STLProps = {
    w: number
    h: number
    onFinishLoading: () => void
    onStartLoading: () => void
    onError: (err: Error) => void
    baseUrl: string
    bodies: string[]
    scene: SceneEntry
    selectedBody: string | null
    setSelectedBody: any
    highlightedBody: string | null
    setHighlightedBody: any
    switchMode: () => void
    partMode: boolean
    rotation: Rotation
    setRotationCallback: (newRotation: Rotation) => void
    selectedBodyInfo: any
    setSelectedBodyInfo: any
    color: any
    customHeaders: any[] | null
}

function STL(props: STLProps) {
    const {
        w,
        h,
        onFinishLoading,
        onStartLoading,
        onError,
        baseUrl,
        bodies,
        scene,
        selectedBody,
        setSelectedBody,
        highlightedBody,
        setHighlightedBody,
        switchMode,
        partMode,
        rotation,
        setRotationCallback,
        selectedBodyInfo,
        setSelectedBodyInfo,
        color,
        customHeaders,
    } = props

    const [stlObject, setStlObject] = useState<any | null>(null)

    const stl = useRef<any>(null)
    const previousUrl = useRef<string>(baseUrl)
    const isSTLLoading = useRef<boolean>(false)

    const callbackcRefs = useRef<any>({})

    useEffect(() => {
        if (!stlObject) {
            return
        }

        stlObject.setCustomHeaders(customHeaders)

        var models = []
        if (partMode) {
            models = [
                {
                    id: 0,
                    filename: baseUrl + selectedBody,
                    name: selectedBody,
                },
            ]
        } else {
            setRotationCallback({ yaw: 0, pitch: 0, roll: 0 })
            models = bodies.map((body, index) => {
                return {
                    id: index,
                    filename: baseUrl + body,
                    name: body,
                }
            })
        }

        if (!partMode && stlObject.get_models_count() == models.length && previousUrl.current == baseUrl) {
            return
        }
        previousUrl.current = baseUrl

        onStartLoading()
        stlObject.hide()
        stlObject.clean()
        stlObject.add_models(
            models.map((model, index) => {
                return {
                    filename: model.filename,
                    name: model.name,
                    id: index,
                }
            }),
        )
    }, [stlObject, bodies, baseUrl, partMode, selectedBody, customHeaders])

    useEffect(() => {
        if (!stlObject) {
            return
        }
        const stlObj = stl.current

        stlObj.setCustomHeaders(customHeaders)

        stlObj.get_models().forEach((model: any, index: number) => {
            var placement = scene[model.name]
            if (!placement || partMode) {
                const distance = stlObj.world_axis_aligned_bounding_box_disatance_to_surface(
                    index,
                    rotation.yaw,
                    rotation.roll,
                    rotation.pitch,
                )
                const offset = stlObj.objectHeight(index, rotation.yaw, rotation.roll, rotation.pitch)

                placement = {
                    x: 0,
                    y: partMode ? Math.abs(distance) : -offset / 100,
                    z: 0,
                    yaw: 0,
                    roll: 0,
                    pitch: 0,
                }
                stlObj.set_rotation(index, rotation.yaw, rotation.roll, rotation.pitch, false)
            }
            stlObj.set_position(index, placement.x, placement.y, placement.z)
            if (model.name == selectedBody) {
                stlObj.set_color(index, "#00cdb8")
            } else if (model.name == highlightedBody) {
                stlObj.set_color(index, "#7480ff")
            } else {
                stlObj.set_color(index, color)
            }

            if (selectedBody == model.name) {
                const dsc = stlObj.get_model_info(index)
                setSelectedBodyInfo(dsc)
            }
        })
        stlObj.set_grid(partMode, Math.max(w, h), 20)
        onFinishLoading()
    }, [selectedBody, highlightedBody, stlObject, partMode, rotation, w, h, color, customHeaders])

    useEffect(() => {
        if (stl.current == null) {
            return
        }

        const replaceListener = (key: string, newCallback: any) => {
            if (callbackcRefs.current[key]) {
                document.removeEventListener(key, callbackcRefs.current[key])
            }
            callbackcRefs.current[key] = newCallback
            document.addEventListener(key, newCallback)
        }

        replaceListener("stl_space_down", () => {
            if (selectedBody) {
                switchMode()
            }
        })

        replaceListener("all_loaded_event", () => {
            const stlObj = stl.current
            stlObj.get_models().forEach((model: any, index: number) => {
                var placement = scene[model.name]
                if (!placement || partMode) {
                    const distance = stlObj.world_axis_aligned_bounding_box_disatance_to_surface(
                        index,
                        rotation.yaw,
                        rotation.roll,
                        rotation.pitch,
                    )
                    const offset = stlObj.objectHeight(index, rotation.yaw, rotation.roll, rotation.pitch)
                    placement = {
                        x: 0,
                        y: partMode ? Math.abs(distance) : -offset / 100,
                        z: 0,
                        yaw: 0,
                        roll: 0,
                        pitch: 0,
                    }
                    stlObj.set_rotation(index, rotation.yaw, rotation.roll, rotation.pitch, false)
                }
                stlObj.set_position(index, placement.x, placement.y, placement.z)

                if (model.name == selectedBody) {
                    stlObj.set_color(index, "#00cdb8")
                } else if (model.name == highlightedBody) {
                    stlObj.set_color(index, "#7480ff")
                } else {
                    stlObj.set_color(index, color)
                }

                stlObj.show()
                if (selectedBody == model.name) {
                    const dsc = stlObj.get_model_info(index)
                    setSelectedBodyInfo(dsc)
                }
            })
            onFinishLoading()
            stlObj.set_grid(partMode, Math.max(w, h), 20)
        })

        replaceListener("stl_prev_down", () => {
            if (!selectedBody) {
                return
            }
            const currentIndex = bodies.indexOf(selectedBody)
            if (currentIndex < 1) {
                return
            }
            setSelectedBody(bodies[currentIndex - 1])
        })

        replaceListener("stl_next_down", () => {
            if (!selectedBody) {
                return
            }
            const currentIndex = bodies.indexOf(selectedBody)
            if (currentIndex == -1 || currentIndex + 1 == bodies.length) {
                return
            }
            setSelectedBody(bodies[currentIndex + 1])
        })

        const step = Math.PI / 2
        replaceListener("stl_a_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw,
                pitch: rotation.pitch - step,
                roll: rotation.roll,
            })
        })

        replaceListener("stl_d_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw,
                pitch: rotation.pitch + step,
                roll: rotation.roll,
            })
        })

        replaceListener("stl_w_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw + step,
                pitch: rotation.pitch,
                roll: rotation.roll,
            })
        })

        replaceListener("stl_s_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw - step,
                pitch: rotation.pitch,
                roll: rotation.roll,
            })
        })

        replaceListener("stl_q_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw,
                pitch: rotation.pitch,
                roll: rotation.roll - step,
            })
        })

        replaceListener("stl_e_down", () => {
            if (!selectedBody) {
                return
            }
            setRotationCallback({
                yaw: rotation.yaw,
                pitch: rotation.pitch,
                roll: rotation.roll + step,
            })
        })

        replaceListener("stl_model_selected", (event: any) => {
            const model_id = event.detail.model
            if (model_id !== null && !partMode) {
                const body = bodies[model_id]
                // prevent
                setSelectedBody(body)
            } else if (!partMode) {
                setSelectedBody(null)
            }
        })
    }, [
        switchMode,
        stlObject,
        selectedBody,
        setSelectedBody,
        scene,
        partMode,
        rotation,
        setRotationCallback,
        w,
        h,
        color,
    ])

    useEffect(() => {
        if (isSTLLoading.current) {
            return
        } else {
            isSTLLoading.current = true
        }

        ;(async () => {
            if (stlObject) {
                return
            }

            await new Promise((resolve, reject) => {
                if ((window as any).StlViewer) {
                    resolve("")
                } else {
                    setTimeout(() => {
                        if ((window as any).StlViewer) {
                            resolve("")
                        }
                    }, 100)
                }
            })

            var models = []
            if (partMode) {
                models = [
                    {
                        id: 0,
                        filename: baseUrl + selectedBody + "?nocache=" + +new Date().getTime(),
                        name: selectedBody,
                    },
                ]
            } else {
                models = bodies.map((body, index) => {
                    return {
                        id: index,
                        filename: baseUrl + body + "?nocache=" + +new Date().getTime(),
                        name: body,
                    }
                })
            }

            const initParams = {
                models: models,
                camerax: -45,
                cameray: 45,
                send_no_model_click_event: true,
                custom_headers: customHeaders,
                all_loaded_callback: () => {
                    const readyEvent = new Event("all_loaded_event")
                    document.dispatchEvent(readyEvent)
                },
                ready_callback: (newStl: any) => {
                    newStl.hide()
                    setStlObject(newStl)
                },
                load_error_callback: (error: any) => {
                    onError(error)
                },
                on_model_mouseclick: (model_id: any, e: any, distance: any, click_type: any) => {
                    if (click_type == 11) {
                        const readyEvent = new Event("stl_space_down")
                        document.dispatchEvent(readyEvent)
                        return
                    }

                    const readyEvent = new CustomEvent("stl_model_selected", {
                        detail: { model: model_id, clickType: click_type },
                    })
                    document.dispatchEvent(readyEvent)
                },
                on_model_mousemove: (model_id: any, e: any, distance: any) => {
                    var i = 0

                    if (model_id == null) {
                        setHighlightedBody(null)
                        return
                    }

                    for (const body of bodies) {
                        if (i == model_id && highlightedBody != body) {
                            setHighlightedBody(body)
                        }
                        i++
                    }
                },
                on_next_down: () => {
                    const readyEvent = new Event("stl_next_down")
                    document.dispatchEvent(readyEvent)
                },
                on_prev_down: () => {
                    const readyEvent = new Event("stl_prev_down")
                    document.dispatchEvent(readyEvent)
                },
                on_space_down: () => {
                    const readyEvent = new Event("stl_space_down")
                    document.dispatchEvent(readyEvent)
                },
                on_c_down: () => {
                    const readyEvent = new Event("stl_c_down")
                    document.dispatchEvent(readyEvent)
                },
                on_a_down: () => {
                    const readyEvent = new Event("stl_a_down")
                    document.dispatchEvent(readyEvent)
                },
                on_w_down: () => {
                    const readyEvent = new Event("stl_w_down")
                    document.dispatchEvent(readyEvent)
                },
                on_d_down: () => {
                    const readyEvent = new Event("stl_d_down")
                    document.dispatchEvent(readyEvent)
                },
                on_s_down: () => {
                    const readyEvent = new Event("stl_s_down")
                    document.dispatchEvent(readyEvent)
                },
                on_q_down: () => {
                    const readyEvent = new Event("stl_q_down")
                    document.dispatchEvent(readyEvent)
                },
                on_e_down: () => {
                    const readyEvent = new Event("stl_e_down")
                    document.dispatchEvent(readyEvent)
                },
            }

            const doc = document.getElementById("stl_cont")
            if (doc) {
                doc.innerHTML = ""
            }
            stl.current = (window as any).StlViewer(doc, initParams)
        })()
    }, [])

    return <div id="stl_cont" className={`h-full w-full bg-base-100`}></div>
}

export default STL
