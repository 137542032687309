import React, { Ref } from "react"
import { useEffect, useState, useCallback, useRef } from "react"
import "./List.css"

import { Cad, useApi, PriceFilter, Sort, ParametricFilter } from "../model/api"

import Item from "./Item"

import InfiniteScroll from "react-infinite-scroller"

type ListProps = {
    isMobile: boolean
    cadList: Cad[]
    onCadClicked: (cad: Cad, index: number, newTab: boolean) => void
    onSearchUpdated: (
        keyword: string,
        priceFilterType: PriceFilter,
        parametricFilter: ParametricFilter,
        sort: Sort,
    ) => void
    onUploadButtonClicked: () => void
    onProfileButtonClicked: () => void
    loadNextPage: () => Promise<boolean>
    searchValue: string
    scrollPositionRef: any
    scrollPos: number
    priceFilter: PriceFilter
    parametricFilter: ParametricFilter
    sort: Sort
}

function List(props: ListProps) {
    const {
        isMobile,
        cadList,
        onCadClicked,
        onSearchUpdated,
        onUploadButtonClicked,
        onProfileButtonClicked,
        searchValue,
        scrollPositionRef,
        scrollPos,
        priceFilter,
        parametricFilter,
        sort,
        loadNextPage,
    } = props
    const [searchText, setSearchText] = useState<string>(searchValue)

    const { user, login, logout, isMod } = useApi()

    const callbackcRefs = useRef<any>({})

    const [mobileFiltersExpanded, setMobileFiltersExpanded] = useState<boolean>(false)

    const [additionalHeaderOffset, setAdditionalHeaderOffset] = useState<string>("pt-[6.5rem] mb-[-6.5rem]")

    const [moreContent, setMoreContent] = useState<boolean>(true)

    const trigerLoadNextPage = useCallback(async () => {
        setMoreContent(await loadNextPage())
    }, [loadNextPage])

    useEffect(() => {
        setSearchText(searchValue)
    }, [cadList, scrollPos])

    useEffect(() => {
        if (mobileFiltersExpanded) {
            if (window.innerWidth < 700) {
                setAdditionalHeaderOffset("pt-[14.5rem] mb-[-14.5rem]")
            } else {
                setAdditionalHeaderOffset("pt-[10.5rem] mb-[-10.5rem]")
            }
        } else {
            if (window.innerWidth > 670) {
                setAdditionalHeaderOffset("pt-[6.5rem]")
            } else {
                setAdditionalHeaderOffset("pt-[6.5rem] mb-[-6.5rem]")
            }
        }
    }, [isMobile, mobileFiltersExpanded, additionalHeaderOffset])

    const searchUpdated = useCallback(
        (keyword: string, priceFilterType: PriceFilter, parametricFilter: ParametricFilter, sort: Sort) => {
            onSearchUpdated(keyword, priceFilterType, parametricFilter, sort)
            setMoreContent(true)
        },
        [cadList],
    )

    const onSearchChange = useCallback(
        (event: any) => {
            const value = event.target.value
            setSearchText(value)
        },
        [cadList],
    )

    const onSearchKeyDown = useCallback(
        (event: any) => {
            if (event.key === "Enter") {
                const value = event.target.value
                if (value.length == 0) {
                    searchUpdated("", priceFilter, parametricFilter, sort)
                } else {
                    searchUpdated(searchText, priceFilter, parametricFilter, sort)
                }
                event.target.blur()
            }
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onSearchBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length == 0) {
                searchUpdated("", priceFilter, parametricFilter, sort)
            }
            setSearchText(value)
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onSearchClick = useCallback(
        (event: any) => {
            searchUpdated(searchText, priceFilter, parametricFilter, sort)
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onLoginClick = useCallback(
        (event: any) => {
            ;(async () => {
                await login()
            })()
        },
        [cadList, searchText],
    )

    const onLogoutClick = useCallback(
        (event: any) => {
            ;(async () => {
                await logout()
            })()
        },
        [cadList, searchText],
    )

    const onExpandFilters = useCallback(
        (event: any) => {
            ;(async () => {
                setMobileFiltersExpanded(!mobileFiltersExpanded)
            })()
        },
        [cadList, mobileFiltersExpanded],
    )

    const onChangeParametricFilter = useCallback(() => {
        var parametric = ParametricFilter.ALL
        if (parametricFilter == ParametricFilter.ALL) {
            parametric = ParametricFilter.PARAMETRIC
        } else if (parametricFilter == ParametricFilter.PARAMETRIC) {
            parametric = ParametricFilter.ALL
        }
        searchUpdated(searchText, priceFilter, parametric, sort)
    }, [cadList, searchText, priceFilter, sort, parametricFilter, searchUpdated])

    const onChangePriceFilter = useCallback(() => {
        var price = PriceFilter.ALL
        if (priceFilter == PriceFilter.ALL) {
            price = PriceFilter.PAID
        } else if (priceFilter == PriceFilter.PAID) {
            price = PriceFilter.FREE
        } else if (priceFilter == PriceFilter.FREE) {
            price = PriceFilter.ALL
        }
        searchUpdated(searchText, price, parametricFilter, sort)
    }, [cadList, searchText, priceFilter, sort, parametricFilter, searchUpdated])

    const onChangeSortFilter = useCallback(() => {
        var type = Sort.NEWEST
        if (sort == Sort.NEWEST) {
            type = Sort.RATING
        } else if (sort == Sort.RATING) {
            type = Sort.POPULAR
        } else if (sort == Sort.POPULAR) {
            if (isMod()) {
                type = Sort.CLAIMS
            } else {
                type = Sort.NEWEST
            }
        } else if (sort == Sort.CLAIMS) {
            type = Sort.NEWEST
        }
        searchUpdated(searchText, priceFilter, parametricFilter, type)
    }, [cadList, searchText, priceFilter, parametricFilter, sort, searchUpdated])

    const priceFilterToDescription = (filter: PriceFilter) => {
        switch (filter) {
            case PriceFilter.ALL:
                return "Paid & Free"
                break
            case PriceFilter.FREE:
                return "Only Free"
                break
            case PriceFilter.PAID:
                return "Only Paid"
                break
        }
    }

    const parametricFilterToDescription = (filter: ParametricFilter) => {
        switch (filter) {
            case ParametricFilter.ALL:
                return "All"
                break
            case ParametricFilter.PARAMETRIC:
                return "Parametric"
                break
        }
    }

    const sortFilterToDescription = (filter: Sort) => {
        switch (filter) {
            case Sort.NEWEST:
                return "Newest"
                break
            case Sort.RATING:
                return "Rating"
                break
            case Sort.POPULAR:
                return "Popular"
                break
            case Sort.CLAIMS:
                return "Claims"
                break
        }
    }

    return (
        <div className="w-screen">
            {!isMobile && (
                <header className="fixed top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-4 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    <div className="join py-4 mx-4">
                        <div className="tooltip tooltip-bottom" data-tip="Sort by: Newest, Rating or Popular">
                            <button className="btn join-item btn-outline w-28" onClick={onChangeSortFilter}>
                                {sortFilterToDescription(sort)}
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip="Filter by: ALL, Only Free, Only Paid"
                        >
                            <button className="btn join-item btn-outline w-28" onClick={onChangePriceFilter}>
                                {priceFilterToDescription(priceFilter)}
                            </button>
                        </div>
                        <div className="tooltip tooltip-bottom" data-tip="Filter by: ALL, Only Parametric">
                            <button
                                className="btn join-item btn-outline w-28"
                                onClick={onChangeParametricFilter}
                            >
                                {parametricFilterToDescription(parametricFilter)}
                            </button>
                        </div>
                    </div>
                    <div className="join py-4 mx-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={"Enter a title, tag, author or description text."}
                        >
                            <input
                                className="input input-bordered join-item focus:outline-none focus:border-neutral-content border-neutral-content bg-base-100"
                                placeholder="Text"
                                onChange={onSearchChange}
                                onBlur={onSearchBlur}
                                onKeyDown={onSearchKeyDown}
                                value={searchText}
                            />
                        </div>
                        <button className="btn btn-accent join-item" onClick={onSearchClick}>
                            Search
                        </button>
                    </div>

                    <div className="join py-4 mx-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Upload a model" : "Login and upload a model"}
                        >
                            <button
                                className="btn btn-outline join-item"
                                disabled={!user || isMobile}
                                onClick={onUploadButtonClicked}
                            >
                                Upload
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                        >
                            <button
                                className="btn btn-outline join-item"
                                disabled={!user || isMobile}
                                onClick={onProfileButtonClicked}
                            >
                                Profile
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Logout" : "Login or create your account"}
                        >
                            <button
                                className="btn btn-outline join-item"
                                onClick={user ? onLogoutClick : onLoginClick}
                            >
                                {user ? "Logout" : "Login"}
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {isMobile && !mobileFiltersExpanded && (
                <header className="fixed h-20 top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-2 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    <div className="py-4 ml-4">
                        <div className="tooltip tooltip-bottom" data-tip="Expand Filters">
                            <button
                                className="btn btn-outline join-item w-16"
                                disabled={!user}
                                onClick={onExpandFilters}
                            >
                                {!mobileFiltersExpanded ? "Filters" : "Hide"}
                            </button>
                        </div>
                    </div>
                    <div className="join py-4 mr-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Upload a model" : "Login and upload a model"}
                        >
                            <button
                                className="btn btn-outline join-item w-16"
                                disabled={true}
                                onClick={onUploadButtonClicked}
                            >
                                Upload
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                        >
                            <button
                                className="btn btn-outline join-item w-15"
                                disabled={!user}
                                onClick={onProfileButtonClicked}
                            >
                                Profile
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Logout" : "Login or create your account"}
                        >
                            <button
                                className="btn btn-outline join-item w-16"
                                onClick={user ? onLogoutClick : onLoginClick}
                            >
                                {user ? "Logout" : "Login"}
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {mobileFiltersExpanded && isMobile && (
                <header>
                    <div className="fixed h-20 top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-2">
                        <div className="py-4 ml-4">
                            <div className="tooltip tooltip-bottom" data-tip="Expand Filters">
                                <button
                                    className="btn btn-outline join-item"
                                    disabled={!user}
                                    onClick={onExpandFilters}
                                >
                                    {!mobileFiltersExpanded ? "Filters" : "Hide"}
                                </button>
                            </div>
                        </div>
                        <div className="join py-4 mr-4">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Upload a model" : "Login and upload a model"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    disabled={true}
                                    onClick={onUploadButtonClicked}
                                >
                                    Upload
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    disabled={!user}
                                    onClick={onProfileButtonClicked}
                                >
                                    Profile
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Logout" : "Login or create your account"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    onClick={user ? onLogoutClick : onLoginClick}
                                >
                                    {user ? "Logout" : "Login"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-base-300 fixed top-16 mt-2 z-40 shadow-md flex justify-around flex-wrap gap-x-2 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                        <div className="join py-2 mx-4">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip="Sort by: Newest, Rating or Popular"
                            >
                                <button
                                    className="btn join-item btn-outline w-24"
                                    onClick={onChangeSortFilter}
                                >
                                    {sortFilterToDescription(sort)}
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip="Filter by: ALL, Only Free, Only Paid"
                            >
                                <button
                                    className="btn join-item btn-outline w-28"
                                    onClick={onChangePriceFilter}
                                >
                                    {priceFilterToDescription(priceFilter)}
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip="Filter by: ALL, Only Parametric"
                            >
                                <button
                                    className="btn join-item btn-outline w-24"
                                    onClick={onChangeParametricFilter}
                                >
                                    {parametricFilterToDescription(parametricFilter)}
                                </button>
                            </div>
                        </div>
                        <div className="join py-2 mb-3 mx-4">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={"Enter a title, tag, author or description text."}
                            >
                                <input
                                    className="input input-bordered join-item focus:outline-none focus:border-neutral-content border-neutral-content bg-base-100"
                                    placeholder="Text"
                                    onChange={onSearchChange}
                                    onBlur={onSearchBlur}
                                    onKeyDown={onSearchKeyDown}
                                    value={searchText}
                                />
                            </div>
                            <button className="btn btn-accent join-item" onClick={onSearchClick}>
                                Search
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {/* <div> */}
            <InfiniteScroll
                className={`flex justify-around flex-wrap m-0 gap-8 ` + additionalHeaderOffset}
                pageStart={0}
                loadMore={trigerLoadNextPage}
                hasMore={moreContent}
            >
                {cadList.map((cad: Cad, index: number) => {
                    return (
                        <Item
                            isMobile={isMobile}
                            key={"item_" + index}
                            cad={cad}
                            index={index}
                            onCadClicked={onCadClicked}
                        ></Item>
                    )
                })}
                <div
                    key={"item_placeholder_0"}
                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                ></div>
                <div
                    key={"item_placeholder_1"}
                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                ></div>
                <div
                    key={"item_placeholder_2"}
                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                ></div>
                <div
                    key={"item_placeholder_3"}
                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                ></div>
                <div
                    key={"item_placeholder_4"}
                    className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                ></div>
            </InfiniteScroll>
        </div>
    )
}

export default List
