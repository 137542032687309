import React, { useCallback, useEffect, useState, useContext } from "react"
import "./Details.css"

import Properties from "./Properties"
import SetupProperties from "./SetupProperties"
import ModelView from "./ModelView"
import { Helmet } from "react-helmet-async"

import { BedSize, SettingsContext } from "../model/settings"
import Description from "./Description"
import SetupDescription from "./SetupDescription"
import {
    Cad,
    Property,
    FileType,
    UploadCadParameters,
    RenderParameters,
    BodyDescription,
    UploadPropertyParameters,
    useApi,
    AbuseType,
    Print,
    EditCad,
} from "../model/api"

export type ModifiedProperty = Property & { currentValue?: string }
type ModifiedCad = Cad & { currentRenderHash?: string }

export enum DetailsMode {
    PRESENT = "present",
    EDIT = "edit",
    UPLOAD = "upload",
}

type DetailsProps = {
    isMobile: boolean
    cad: Cad
    onBackButtonClicked: () => void
    mode: DetailsMode
    file?: File
    type: FileType
    reload: () => void
    makeUpload?: (
        file: File,
        properties: UploadCadParameters,
        capturedImages: Blob,
    ) => Promise<Cad | undefined>
    showSuccessMessage: (message: string) => void
    showErrorMessage: (message: string) => void
}

export type Rotation = {
    yaw: number
    pitch: number
    roll: number
}

enum FileValidState {
    Empty = "empty",
    Valid = "valid",
    Invalid = "Invalid",
}

const step = Math.PI / 2

enum MobileDetailTab {
    DETAILS = "details",
    MODEL = "model",
    PROPERTIES = "properties",
}

export function Details(props: DetailsProps) {
    const { REACT_APP_API_URL } = process.env
    const {
        isMobile,
        cad,
        onBackButtonClicked,
        mode,
        file,
        makeUpload,
        type,
        reload,
        showErrorMessage,
        showSuccessMessage,
    } = props
    const {
        user,
        renderCad,
        apiUrl,
        buyCad,
        isCadOwned,
        isMod,
        addAbuseClaim,
        addPrint,
        getPrints,
        removePrint,
        removeCad,
        editCad,
    } = useApi()

    const [extendedMode, setExtendedMode] = useState<DetailsMode>(mode)
    const [projectMode, setProjectMode] = useState<boolean>(true)
    const [modifedCad, setModifiedCad] = useState<ModifiedCad>(cad)
    const [modifiedProperties, setModifiedProperties] = useState<ModifiedProperty[]>([])

    const [price, setPrice] = useState<number>(0)
    const [modifiedTitle, setModifiedTitle] = useState<string>("")
    const [description, setDescription] = useState<string>(cad.description)
    const [bodyDescriptions, setBodyDescriptions] = useState<any>({})
    const [tags, setTags] = useState<string[]>([])
    const [addedProperties, setAddedProperties] = useState<Property[]>([])
    const [color, setColor] = useState<string>(cad.color)
    const [author, setAuthor] = useState<string>("Author")
    const [url, setUrl] = useState<string>("")
    const [dataValid, setDataValid] = useState<boolean>(false)

    const [capturedRecording, setCapturedRecording] = useState<Blob | null>(null)

    var body = null

    if (extendedMode == DetailsMode.PRESENT) {
        const hashParam = window.location.hash.substring(1)
        if (hashParam.length != 0) {
            body = hashParam.replace("item_", "")
        }
    }
    const [selectedBody, setSelectedBody] = useState<string | null>(body)
    const [selectedBodyInfo, setSelectedBodyInfo] = useState<any>(null)
    const { currentBedSize, setBedSizeCallback, bedSizes, setCustomBedSizeCallback } =
        useContext(SettingsContext)
    const [rotation, setRotation] = useState<Rotation>({
        yaw: 0,
        pitch: 0,
        roll: 0,
    })
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [gifMode, setGifMode] = useState<boolean>(false)
    const [capture, setCapture] = useState<boolean>(false)

    const [customX, setCustomX] = useState<number>(0)
    const [customY, setCustomY] = useState<number>(0)

    const [hiddenProperties, setHiddenProperties] = useState<boolean>(cad.properties.length == 0)
    const [hiddenDescription, setHiddenDescription] = useState<boolean>(false)

    const [reportAbuseType, setReportAbuseType] = useState<AbuseType>(AbuseType.TERMS)
    const [reportAbuseMessage, setReportAbuseMessage] = useState<string>("")
    const [reportAbuseUrl, setReportAbuseUrl] = useState<string | null>(null)

    const [printComment, setPrintComment] = useState<string | null>(null)
    const [uploadFile1, setUploadFile1] = useState<File | undefined>(undefined)
    const [uploadFile2, setUploadFile2] = useState<File | undefined>(undefined)
    const [uploadFile3, setUploadFile3] = useState<File | undefined>(undefined)
    const [rating, setRating] = useState<number>(5)

    const [prints, setPrints] = useState<Print[]>([])

    const [printsImages, setPrintsImages] = useState<string[]>([])
    const [focusedPrintsImage, setFocusedPrintsImage] = useState<string>("")

    const [mobileTab, setMobileTab] = useState<MobileDetailTab>(MobileDetailTab.MODEL)

    useEffect(() => {
        setModifiedTitle(cad.title)
        setDescription(cad.description)

        const mappedDescriptions: any = {}
        cad.bodiesDescription.forEach((body) => {
            mappedDescriptions[body.bodyName] = body.description
        })
        setBodyDescriptions(mappedDescriptions)
        setTags(cad.tags)
        setColor(cad.color)
        setUrl(cad.url ? cad.url : "")
    }, [extendedMode, cad])

    useEffect(() => {
        const willLoad =
            isMod() ||
            isCadOwned(cad.id) ||
            cad.price == null ||
            cad.price == 0 ||
            (user && cad.userId == user.id)
        if (!willLoad) {
            setIsLoading(false)
        }
    }, [cad, user])

    useEffect(() => {
        ;(async () => {
            setModifiedCad(structuredClone(cad))
            setModifiedProperties(structuredClone(cad.properties))

            const newPrints = await getPrints(cad.id)
            setPrints(newPrints)
        })()
    }, [cad])

    useEffect(() => {
        if (modifiedTitle.length > 0 && description.length > 0) {
            setDataValid(true)
        } else {
            setDataValid(false)
        }
    }, [modifiedTitle, description])

    const hideShowProperties = useCallback(() => {
        setHiddenProperties(!hiddenProperties)
    }, [cad, hiddenProperties])

    const hideShowDescription = useCallback(() => {
        setHiddenDescription(!hiddenDescription)
    }, [cad, hiddenDescription])

    const handleProjectModeSwitch = useCallback(() => {
        setProjectMode(!projectMode)
    }, [projectMode, cad])

    const onFinishLoading = useCallback(() => {
        setIsLoading(false)
    }, [cad])

    const onStartLoading = useCallback(() => {
        setIsLoading(true)
    }, [cad])

    const onError = useCallback(
        (error: Error) => {
            setIsLoading(false)
        },
        [cad],
    )

    const handleRemovePrint = useCallback(
        async (printId: string) => {
            const result = await removePrint(printId)
            reload()
        },
        [cad],
    )

    const setPrintRating = useCallback(
        (rating: number) => {
            setRating(rating)
        },
        [cad],
    )

    const handleRender = useCallback(() => {
        ;(async () => {
            const renderParameters: RenderParameters = {}

            if (isMobile) {
                setMobileTab(MobileDetailTab.MODEL)
            }

            for (const prop of modifiedProperties) {
                if (prop.currentValue && !prop.readonly) {
                    renderParameters[prop.cellAddress] =
                        typeof prop.currentValue == "number"
                            ? (prop.currentValue as number).toString()
                            : prop.currentValue
                }
            }

            setIsLoading(true)
            const result = await renderCad(cad.id, renderParameters)
            if (!result) {
                showErrorMessage("Error uploading a model")
                return
            }

            const newCad: ModifiedCad = structuredClone(cad)
            newCad.currentRenderHash = result.renderHash

            const newProperties: ModifiedProperty[] = structuredClone(cad.properties)

            for (const prop of newProperties) {
                const resultProp = result.properties.find(
                    (resultProp) => resultProp.cellAddress == prop.cellAddress,
                )
                if (!resultProp) {
                    continue
                }
                prop.currentValue = resultProp.value
            }
            setModifiedCad(newCad)
            setModifiedProperties(newProperties)
        })()
    }, [cad, renderCad, modifiedProperties])

    const handlePopertyChange = useCallback(
        (property: ModifiedProperty, newValue: string) => {
            for (const prop of modifiedProperties) {
                if (prop.cellAddress == property.cellAddress) {
                    if (newValue == property.value) {
                        delete prop.currentValue
                    } else {
                        prop.currentValue = newValue
                    }
                }
            }
            setModifiedProperties(modifiedProperties)
        },
        [cad, modifiedProperties],
    )

    const setRotationCallback = useCallback((newRotation: Rotation) => {
        setRotation(newRotation)
    }, [])

    const newBodySelectedBody = useCallback(
        (body: string | null) => {
            const newUrl = new URL(window.location.toString())
            newUrl.hash = body ? body : ""
            // eslint-disable-next-line no-restricted-globals
            history.pushState({}, "", newUrl)
            setSelectedBody(body)
        },
        [cad, modifiedProperties],
    )

    const handleDefault = useCallback(() => {
        if (isMobile) {
            setMobileTab(MobileDetailTab.MODEL)
        }
        setIsLoading(true)
        setModifiedCad(structuredClone(cad))
        setModifiedProperties(structuredClone(cad.properties))
    }, [cad, modifiedProperties])

    const onXValueChange = useCallback((event: any) => {
        const value = event.target.value
        setCustomX(value)
    }, [])

    const onYValueChange = useCallback((event: any) => {
        const value = event.target.value
        setCustomY(value)
    }, [])

    const onBlurX = useCallback((event: any) => {
        const value = event.target.value
        const number = parseInt(value)
        if (number) {
            setCustomX(number)
        } else {
            setCustomX(0)
        }
    }, [])

    const onBlurY = useCallback((event: any) => {
        const value = event.target.value
        const number = parseInt(value)
        if (number) {
            setCustomY(number)
        } else {
            setCustomY(0)
        }
    }, [])

    const onTitleValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 20) {
                setModifiedTitle(value)
            }
        },
        [cad, modifedCad],
    )

    const onTitleBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 20) {
                setModifiedTitle(value)
            }
        },
        [cad, modifedCad],
    )

    const handleOpenBuyModal = useCallback(() => {
        ;(async () => {
            if (!document) {
                return
            }
            const element = document.getElementById("buy_cad")
            if (!element) {
                return
            }
            ;(element as any).showModal()
        })()
    }, [cad, user])

    const handleBuyCad = useCallback(() => {
        ;(async () => {
            if (user && cad) {
                const url = await buyCad(cad.id)
                if (!url) {
                    showErrorMessage("Buy model error")
                    return
                }
                window.open(url)
            }
        })()
    }, [cad, user])

    const handleDownload = useCallback(() => {
        var dstModel = cad.bodies.length > 0 && selectedBody ? selectedBody : cad.bodies[0]
        document.dispatchEvent(
            new CustomEvent("download_single", {
                detail: {
                    name: dstModel,
                    fileName: dstModel,
                },
            }),
        )
    }, [cad, modifiedProperties, selectedBody])

    const handleDownloadAll = useCallback(() => {
        document.dispatchEvent(
            new CustomEvent("download_all", {
                detail: {
                    fileName: cad.title.replace(" ", "_") + ".zip",
                },
            }),
        )
    }, [cad, modifiedProperties])

    const handleEdit = useCallback(() => {
        ;(async () => {
            if (extendedMode == DetailsMode.PRESENT) {
                setExtendedMode(DetailsMode.EDIT)
            } else if (extendedMode == DetailsMode.EDIT) {
                const formatedBodyDescriptions: BodyDescription[] = Object.keys(bodyDescriptions).map(
                    (key) => {
                        return {
                            bodyName: key,
                            description: bodyDescriptions[key],
                        }
                    },
                )
                const editedCad: EditCad = {
                    title: modifiedTitle,
                    tags: tags,
                    url: url,
                    description: description,
                    color: color,
                    bodiesDescription: formatedBodyDescriptions,
                }

                if (!isMod() && price != cad.price) {
                    editedCad.price = price
                }

                const result = await editCad(cad.id, editedCad)
                reload()

                if (result) {
                    showSuccessMessage("Model edit succeded")
                } else {
                    showErrorMessage("Model edit failed")
                    return
                }
                setExtendedMode(DetailsMode.PRESENT)
            }
        })()
    }, [
        cad,
        modifiedProperties,
        setExtendedMode,
        extendedMode,
        modifiedTitle,
        tags,
        url,
        description,
        color,
        price,
        bodyDescriptions,
    ])

    const openDeleteModal = useCallback(() => {
        ;(async () => {
            if (!document) {
                return
            }
            const element = document.getElementById("remove_cad")
            if (!element) {
                return
            }
            ;(element as any).showModal()
        })()
    }, [cad, modifiedProperties, setExtendedMode, extendedMode])

    const openImageModel = useCallback(
        (image: string, images?: string[]) => {
            ;(async () => {
                if (!document) {
                    return
                }

                setPrintsImages(images ? images : [])
                setFocusedPrintsImage(image)

                const element = document.getElementById("print_image")
                if (!element) {
                    return
                }
                ;(element as any).showModal()
            })()
        },
        [cad],
    )

    const handleDelete = useCallback(() => {
        ;(async () => {
            const result = await removeCad(cad.id)
            if (result) {
                showSuccessMessage("Model deletion succeded")
            } else {
                showErrorMessage("Model deletion failed")
                return
            }
            onBackButtonClicked()
        })()
    }, [cad, modifiedProperties, setExtendedMode, extendedMode])

    const handleUpload = useCallback(() => {
        if (capture) {
            return
        }
        if (!gifMode && !capturedRecording) {
            setGifMode(true)
            setHiddenDescription(true)
            setHiddenProperties(true)
            return
        }

        if (gifMode && !capturedRecording) {
            setCapture(true)
            return
        }
        const formatedBodyDescriptions: BodyDescription[] = Object.keys(bodyDescriptions).map((key) => {
            return {
                bodyName: key,
                description: bodyDescriptions[key],
            }
        })

        const formatedProperties: UploadPropertyParameters[] = addedProperties.map((property) => {
            return {
                cellAddress: property.cellAddress,
                name: property.name,
                type: property.type ? property.type : "mm",
                description: property.description,
                readonly: property.readonly,
            }
        })

        const uploadParameters: UploadCadParameters = {
            title: modifiedTitle,
            tags: tags,
            color: color,
            description: description,
            properties: formatedProperties,
            bodiesDescription: formatedBodyDescriptions,
            type: type,
        }

        if (url) {
            uploadParameters.url = url
        }

        if (price > 0) {
            uploadParameters.price = price
        }

        if (!file) {
            return
        }

        ;(async () => {
            if (!makeUpload) {
                return
            }

            if (!capturedRecording) {
                return
            }

            const result = await makeUpload(file, uploadParameters, capturedRecording)
            if (result) {
                showSuccessMessage("Upload succeded")
            } else {
                showErrorMessage("Upload failed")
                return
            }
            const newUrl = new URL(window.location.toString())
            newUrl.pathname = `/model/${result.id}`
            newUrl.hash = ""
            window.open(newUrl, "_self")
        })()
    }, [
        cad,
        capture,
        makeUpload,
        capturedRecording,
        gifMode,
        modifiedProperties,
        selectedBody,
        bodyDescriptions,
        addedProperties,
        modifiedTitle,
        tags,
        description,
        file,
    ])

    const handleUploadReset = useCallback(() => {
        setCapturedRecording(null)
        setHiddenDescription(false)
        setHiddenProperties(false)
        if (gifMode) {
            setGifMode(false)
            return
        }
        setModifiedTitle("")
        setDescription("")
        setBodyDescriptions({})
        setTags([])
        setAddedProperties([])

        setDataValid(false)
    }, [cad, gifMode])

    const onAbuseUrlChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 500) {
                setReportAbuseUrl(value)
            }
        },
        [cad, modifedCad],
    )

    const onAbuseMessageChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 3072) {
                setReportAbuseMessage(value)
            }
        },
        [cad, modifedCad],
    )

    const handleAbuseReportUpload = useCallback(async () => {
        const result = await addAbuseClaim(
            cad.id,
            reportAbuseType,
            reportAbuseUrl && reportAbuseUrl.length > 0 ? reportAbuseUrl : null,
            reportAbuseMessage,
        )
        reload()

        if (result) {
            showSuccessMessage("Abuse report added")
        } else {
            showErrorMessage("Abuse report add failed")
        }
    }, [reportAbuseType, reportAbuseMessage, reportAbuseUrl, cad])

    const onPrintMessageChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 3072) {
                setPrintComment(value)
            }
        },
        [cad, modifedCad],
    )

    const handlePrintUpload = useCallback(async () => {
        if (!(uploadFile1 || uploadFile2 || uploadFile3) || !printComment) {
            return
        }

        const files = [uploadFile1, uploadFile2, uploadFile3].filter((file) => file != null)
        const result = await addPrint(cad.id, printComment, rating, files)
        reload()
        if (result) {
            showSuccessMessage("Print added")
        } else {
            showErrorMessage("Print add failed")
        }
    }, [uploadFile1, uploadFile2, uploadFile3, cad, printComment, rating])

    const handleCaptureReady = useCallback(
        (recording: any) => {
            setCapture(false)
            setCapturedRecording(recording)
        },
        [cad, gifMode],
    )

    const setCustomBoardSize = useCallback(
        (event: any) => {
            if (customX == 0 || customY == 0) {
                return
            }
            setCustomBedSizeCallback(customX, customY)
            setBedSizeCallback({ x: customX, y: customY })
        },
        [customX, customY],
    )

    const handleFileChange1 = useCallback((event: any) => {
        if (event.target.files) {
            const targetFile = event.target.files[0] as File
            setUploadFile1(targetFile)
        } else {
            setUploadFile1(undefined)
        }
    }, [])

    const handleFileChange2 = useCallback((event: any) => {
        if (event.target.files) {
            const targetFile = event.target.files[0] as File
            setUploadFile2(targetFile)
        } else {
            setUploadFile2(undefined)
        }
    }, [])

    const handleFileChange3 = useCallback((event: any) => {
        if (event.target.files) {
            const targetFile = event.target.files[0] as File
            setUploadFile3(targetFile)
        } else {
            setUploadFile3(undefined)
        }
    }, [])

    const abuseTypeToDescription = (type: AbuseType) => {
        switch (type) {
            case AbuseType.COPYRIGHT:
                return "Copyright"
                break
            case AbuseType.OTHER:
                return "Other"
                break
            case AbuseType.QUALITY:
                return "Poor quality"
                break
            case AbuseType.TERMS:
                return "Terms & Conditions"
                break
        }
    }

    if (!isMobile) {
        return (
            <div>
                <Helmet>
                    <title>PrintedMachines {cad.title}</title>
                    <meta name="description" content={cad.description.replace(/(.{155})..+/, "$1\u2026")} />
                </Helmet>
                <dialog id="ikijka" className="modal">
                    <div className="modal-box">
                        <h3 className="font-bold">Add Print</h3>
                        <div className="modal-action">
                            <div className="inline bg-transparent w-full h-full">
                                {user && cad.userId != user.id && (
                                    <div className="rating">
                                        <input
                                            type="radio"
                                            name="rating-4"
                                            className="mask mask-star-2 bg-accent"
                                            onClick={() => setPrintRating(1)}
                                            checked={rating == 1}
                                        />
                                        <input
                                            type="radio"
                                            name="rating-4"
                                            className="mask mask-star-2 bg-accent"
                                            onClick={() => setPrintRating(2)}
                                            checked={rating == 2}
                                        />
                                        <input
                                            type="radio"
                                            name="rating-4"
                                            className="mask mask-star-2 bg-accent"
                                            onClick={() => setPrintRating(3)}
                                            checked={rating == 3}
                                        />
                                        <input
                                            type="radio"
                                            name="rating-4"
                                            className="mask mask-star-2 bg-accent"
                                            onClick={() => setPrintRating(4)}
                                            checked={rating == 4}
                                        />
                                        <input
                                            type="radio"
                                            name="rating-4"
                                            className="mask mask-star-2 bg-accent"
                                            onClick={() => setPrintRating(5)}
                                            checked={rating == 5}
                                        />
                                    </div>
                                )}
                                <label className="form-control w-full mt-4">
                                    <div className="label mb-[-5px]">
                                        <span className="label-text">Message</span>
                                    </div>
                                    <textarea
                                        className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                        placeholder=""
                                        value={printComment ? printComment : ""}
                                        onChange={onPrintMessageChange}
                                        onBlur={onPrintMessageChange}
                                    ></textarea>
                                </label>
                                <div className="flex justify-center mb-4">
                                    <label className="form-control w-80 max-w-xs mt-5">
                                        <div className="label">
                                            <span className="label-text">Photos</span>
                                        </div>
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file1"
                                            onChange={handleFileChange1}
                                        />
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file2"
                                            onChange={handleFileChange2}
                                        />
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file3"
                                            onChange={handleFileChange3}
                                        />
                                    </label>
                                </div>
                                <form method="dialog">
                                    <button className="btn" onClick={handlePrintUpload}>
                                        Add
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
                <dialog id="add_report" className="modal">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Report</h3>
                        <div className="modal-action">
                            <div className="inline bg-transparent w-full h-full mx-10 my-4">
                                <div className="text-left font-bold">Reason:</div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.TERMS)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.TERMS)
                                            }}
                                            checked={reportAbuseType == AbuseType.TERMS}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.COPYRIGHT)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.COPYRIGHT)
                                            }}
                                            checked={reportAbuseType == AbuseType.COPYRIGHT}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.QUALITY)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.QUALITY)
                                            }}
                                            checked={reportAbuseType == AbuseType.QUALITY}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.OTHER)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.OTHER)
                                            }}
                                            checked={reportAbuseType == AbuseType.OTHER}
                                        />
                                    </label>
                                </div>

                                {reportAbuseType == AbuseType.COPYRIGHT && (
                                    <input
                                        type="text"
                                        className="input focus:outline-none input-bordered input w-full mt-4"
                                        placeholder="Url (optional)"
                                        value={reportAbuseUrl ?? ""}
                                        onChange={onAbuseUrlChange}
                                        onBlur={onAbuseUrlChange}
                                    />
                                )}

                                <label className="form-control w-full mt-4">
                                    <div className="label mb-[-5px]">
                                        <span className="label-text">Description</span>
                                    </div>
                                    <textarea
                                        className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                        placeholder=""
                                        value={reportAbuseMessage}
                                        onChange={onAbuseMessageChange}
                                        onBlur={onAbuseMessageChange}
                                    ></textarea>
                                </label>
                                <form method="dialog">
                                    <button className="btn" onClick={handleAbuseReportUpload}>
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
                <dialog id="buy_cad" className="modal">
                    <div className="modal-box w-96">
                        <h3 className="font-bold text-lg">
                            Buy {cad.title} model for ${cad.price}?
                        </h3>
                        <p className="text-sm mb-2">
                            All 3D models purchased and downloaded through the printedmachines.com platform
                            are digital products. Once the user has initiated the download of the product, it
                            is non-refundable with the exception when a product is determined to be defective
                            or does not correspond to the description provided.
                        </p>
                        <a className="link" href="/refund" target="_blank" rel="noopener noreferrer">
                            Read Refunds policy
                        </a>
                        <form method="dialog">
                            <div className="modal-action w-full flex justify-between">
                                <button className="btn btn-outline w-24">Cancel</button>
                                <button className="btn btn-accent w-24" onClick={handleBuyCad}>
                                    Buy
                                </button>
                            </div>
                        </form>
                    </div>
                </dialog>
                <dialog id="remove_cad" className="modal">
                    <div className="modal-box w-64">
                        <h3 className="font-bold text-lg">Remove model?</h3>
                        <div className="modal-action w-full">
                            <form method="dialog w-full">
                                <div className="w-full">
                                    <button className="btn btn-outline mr-10">Cancel</button>
                                    <button className="btn btn-error" onClick={handleDelete}>
                                        Remove
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </dialog>
                <dialog id="print_image" className="modal">
                    <div className="modal-box max-w-full h-full">
                        <div className="carousel carousel-center rounded-box w-full h-full">
                            {focusedPrintsImage && (
                                <div key={"print_image_magnified_"} className="carousel-item">
                                    <img
                                        className="h-full w-full"
                                        src={`${REACT_APP_API_URL || "http://localhost"}/${focusedPrintsImage}`}
                                    />
                                </div>
                            )}
                            {printsImages
                                .filter((printUrl) => !(printUrl == focusedPrintsImage))
                                .map((printUrl, index) => {
                                    return (
                                        <div key={"print_image_magnified_" + index} className="carousel-item">
                                            <img
                                                className="h-full w-full"
                                                src={`${REACT_APP_API_URL || "http://localhost"}/${printUrl}`}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </dialog>
                <dialog id="remove_print" className="modal">
                    <div className="modal-box w-64">
                        <h3 className="font-bold text-lg">Remove print</h3>
                        <div className="modal-action w-full">
                            <form method="dialog w-full flex justify-between">
                                <button className="btn btn-outline">Cancel</button>
                                <button className="btn btn-error" onClick={handleDelete}>
                                    Remove
                                </button>
                            </form>
                        </div>
                    </div>
                </dialog>
                {isLoading && (
                    <div className="flex w-screen h-screen bg-transparent z-100 absolute top-0 left-0">
                        <div className="m-auto bg-transparent">
                            <div className="bg-base-200 py-3 px-4 rounded-lg">
                                <span className="mt-1 loading loading-bars loading-lg"></span>
                            </div>
                        </div>
                    </div>
                )}
                {(extendedMode == DetailsMode.EDIT || extendedMode == DetailsMode.UPLOAD) && (
                    <div className="text-center text-4xl mt-5 absolute w-screen z-20">
                        <div className="flex justify-center">
                            <input
                                type="text"
                                placeholder="Title"
                                value={modifiedTitle}
                                className="input input-bordered w-56 focus:outline-none"
                                onBlur={onTitleBlur}
                                onChange={onTitleValueChange}
                            />
                            <div className="inline pl-4">by {cad.author || user?.displayName}</div>
                        </div>
                    </div>
                )}
                {extendedMode == DetailsMode.PRESENT && (
                    <div>
                        {!cad.author && (
                            <div className="text-center text-4xl mt-5 absolute w-screen z-20">
                                {cad.title}
                            </div>
                        )}
                        {cad.author && (
                            <div className="text-center text-4xl mt-5 absolute w-screen z-20">
                                {cad.title + " by "}
                                <a className="link" href={`/user/${cad.author}`}>
                                    {cad.author}
                                </a>
                            </div>
                        )}
                    </div>
                )}

                <div className="flex py-2 mb-2 fixed left-5 top-3 z-20">
                    <button className="btn btn-outline" onClick={onBackButtonClicked}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 ml-[-4px] z-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={1.5}
                        >
                            <path
                                d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                fillRule="nonzero"
                            />
                        </svg>
                        <div className="ml-0">Back</div>
                    </button>
                    {!projectMode && (
                        <div className="join">
                            <button
                                className="btn btn-outline ml-2 join-item"
                                onClick={handleProjectModeSwitch}
                            >
                                <kbd className="kbd kbd-sm bg-transparent hover-shadow-white mr-[-5px]">
                                    Space
                                </kbd>
                                <div className="mr-[-8px]">View: Part</div>
                            </button>
                            <div className="dropdown dropdown-hover join-item">
                                <div tabIndex={0} role="button" className="btn btn-outline join-item">
                                    Bed:
                                    {`${currentBedSize.x}x${currentBedSize.y}mm`}
                                </div>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box"
                                >
                                    {bedSizes.map((bedSize: BedSize, index: number) => {
                                        if (!bedSize.custom) {
                                            return (
                                                <li key={"bedSize_" + index} className="">
                                                    <a
                                                        onClick={() => {
                                                            setBedSizeCallback(bedSize)
                                                        }}
                                                    >{`${bedSize.x}x${bedSize.y}mm`}</a>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li key={"bedSize_" + index} className="w-32">
                                                    <a
                                                        className=""
                                                        onClick={() => {
                                                            setBedSizeCallback(bedSize)
                                                        }}
                                                    >
                                                        <div>{`${bedSize.x}x${bedSize.y}mm`}</div>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    })}
                                    <li className="text-center">
                                        <a
                                            onClick={() => {
                                                if (!document) {
                                                    return
                                                }
                                                const element = document.getElementById("degimi")
                                                if (!element) {
                                                    return
                                                }
                                                ;(element as any).showModal()
                                            }}
                                        >
                                            Custom
                                        </a>
                                    </li>
                                    <dialog id="degimi" className="modal">
                                        <div className="modal-box w-80 h-40">
                                            <h3 className="font-bold text-lg">Custom Bed Size</h3>
                                            <div className="modal-action">
                                                <div className="inline bg-transparent">
                                                    <input
                                                        type="text"
                                                        className="input focus:outline-none input-bordered input w-20 text-right"
                                                        value={customX}
                                                        onChange={onXValueChange}
                                                        onBlur={onBlurX}
                                                    />
                                                    <div className="inline mx-3">X</div>
                                                    <input
                                                        type="text"
                                                        className="input focus:outline-none input-bordered input w-20 text-right"
                                                        value={customY}
                                                        onChange={onYValueChange}
                                                        onBlur={onBlurY}
                                                    />
                                                </div>
                                                <form method="dialog">
                                                    <button className="btn" onClick={setCustomBoardSize}>
                                                        Set
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </dialog>
                                </ul>
                            </div>
                            <div className="join join-vertical join-item">
                                <div className="btn btn-outline join-item hover:bg-transparent hover:text-base-content m-0 p-0">
                                    <button
                                        className="btn btn-ghost w-20 rounded-none hover:rounded-none"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw - step,
                                                pitch: rotation.pitch,
                                                roll: rotation.roll,
                                            })
                                        }}
                                    >
                                        <div>-</div>
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">w</kbd>
                                    </button>
                                    <div className="mx-[-20px]">Yaw</div>
                                    <button
                                        className="btn btn-ghost w-20 rounded-none hover:rounded-none rounded-tr-lg hover:rounded-tr-lg"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw + step,
                                                pitch: rotation.pitch,
                                                roll: rotation.roll,
                                            })
                                        }}
                                    >
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">s</kbd>+
                                    </button>
                                </div>
                                <div className="btn btn-outline join-item hover:bg-transparent hover:text-base-content m-0 p-0">
                                    <button
                                        className="btn btn-ghost w-20 rounded-none hover:rounded-none"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw,
                                                pitch: rotation.pitch - step,
                                                roll: rotation.roll,
                                            })
                                        }}
                                    >
                                        <div>-</div>
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">a</kbd>
                                    </button>
                                    <div className="mx-[-24px]">Pitch</div>
                                    <button
                                        className="btn btn-ghost w-20 rounded-none rounded-none hover:rounded-none"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw,
                                                pitch: rotation.pitch + step,
                                                roll: rotation.roll,
                                            })
                                        }}
                                    >
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">d</kbd>
                                        <div>+</div>
                                    </button>
                                </div>
                                <div className="btn btn-outline join-item hover:bg-transparent hover:text-base-content m-0 p-0">
                                    <button
                                        className="btn btn-ghost w-20 rounded-none hover:rounded-none"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw,
                                                pitch: rotation.pitch,
                                                roll: rotation.roll - step,
                                            })
                                        }}
                                    >
                                        <div>-</div>
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">e</kbd>
                                    </button>
                                    <div className="mx-[-19px]">Roll</div>
                                    <button
                                        className="btn btn-ghost w-20 rounded-none hover:rounded-none rounded-br-lg hover:rounded-br-lg"
                                        onClick={() => {
                                            setRotation({
                                                yaw: rotation.yaw,
                                                pitch: rotation.pitch,
                                                roll: rotation.roll + step,
                                            })
                                        }}
                                    >
                                        <kbd className="kbd kbd-sm bg-transparent hover-shadow-white">q</kbd>
                                        <div>+</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {projectMode && (
                        <button
                            className="btn btn-outline ml-2"
                            onClick={handleProjectModeSwitch}
                            disabled={!selectedBody}
                        >
                            <kbd className="kbd kbd-sm bg-transparent mr-[-5px]">Space</kbd>
                            <div className="ml-0">View: Project</div>
                        </button>
                    )}
                </div>
                {extendedMode == DetailsMode.UPLOAD && (
                    <div>
                        <div className="join flex fixed right-6 top-5 z-40 justify-end drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                            <button
                                className="join-item btn btn-error w-40 h-10 z-40"
                                onClick={handleUploadReset}
                                disabled={capture}
                            >
                                {!gifMode && "Reset"}
                                {gifMode && !capturedRecording && "Back: Details"}
                                {gifMode && capturedRecording && "Back: Details"}
                            </button>
                            <button
                                className="join-item btn btn-accent w-40 h-10 z-40"
                                onClick={handleUpload}
                                disabled={!dataValid}
                            >
                                {!gifMode && "Next: Thumbnail"}
                                {gifMode && !capturedRecording && !capture && "Start Capture"}
                                {gifMode && capture && (
                                    <div className="flex justify-items-center">
                                        <div className="inline mr-2 mt-1">Capturing</div>
                                        <span className="loading loading-spinner"></span>
                                    </div>
                                )}
                                {gifMode && capturedRecording && "Upload"}
                            </button>
                        </div>
                    </div>
                )}

                {cad.price &&
                    !isCadOwned(cad.id) &&
                    !isMod() &&
                    !(user && cad.userId == user.id) &&
                    extendedMode == DetailsMode.PRESENT && (
                        <div className="flex fixed right-6 top-5 z-40 justify-end drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Buy model" : "Login to buy"}
                            >
                                <button
                                    className="btn btn-accent w-40 h-10 z-40"
                                    disabled={!user}
                                    onClick={handleOpenBuyModal}
                                >
                                    {"Buy for $" + cad.price}
                                </button>
                            </div>
                        </div>
                    )}

                {(!cad.price || isCadOwned(cad.id) || isMod() || (user && cad.userId == user.id)) &&
                    extendedMode == DetailsMode.PRESENT &&
                    cad.bodies.length == 1 &&
                    cad.userId != user?.id && (
                        <div className="flex fixed right-6 top-5 z-40 justify-end drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                            <button className="btn btn-accent w-40 h-10 z-40" onClick={handleDownload}>
                                {"Download " + cad.bodies[0]}
                            </button>
                        </div>
                    )}

                {(!cad.price || isCadOwned(cad.id) || isMod() || (user && cad.userId == user.id)) &&
                    extendedMode != DetailsMode.UPLOAD &&
                    cad.bodies.length == 1 &&
                    (cad.userId == user?.id || isMod()) && (
                        <div className="join flex fixed right-6 top-5 z-40 justify-end drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                            {(cad.userId == user?.id || isMod()) && (
                                <div>
                                    {extendedMode == DetailsMode.EDIT && (
                                        <div className="tooltip tooltip-bottom" data-tip={"Remove cad"}>
                                            <button
                                                className="join-item btn btn-error w-24 h-10 z-40"
                                                onClick={openDeleteModal}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    )}
                                    <button
                                        className="join-item btn btn-primary w-24 h-10 z-40"
                                        onClick={handleEdit}
                                    >
                                        {extendedMode == DetailsMode.EDIT ? "Save" : "Edit"}
                                    </button>
                                </div>
                            )}
                            {extendedMode == DetailsMode.PRESENT && (
                                <button
                                    className="join-item btn btn-accent w-40 h-10 z-40"
                                    onClick={handleDownload}
                                >
                                    {"Download " + cad.bodies[0]}
                                </button>
                            )}
                        </div>
                    )}

                {(!cad.price || isCadOwned(cad.id)) &&
                    extendedMode != DetailsMode.UPLOAD &&
                    cad.bodies.length > 1 && (
                        <div className="join flex fixed right-6 top-5 z-50 justify-end drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                            {extendedMode == DetailsMode.EDIT && (
                                <div className="tooltip tooltip-bottom" data-tip={"Remove cad"}>
                                    <button
                                        className="join-item btn btn-error w-24 h-10 z-40"
                                        onClick={openDeleteModal}
                                    >
                                        Remove
                                    </button>
                                </div>
                            )}
                            {(cad.userId == user?.id || isMod()) && (
                                <button
                                    className="join-item btn btn-primary w-24 h-10 z-40"
                                    onClick={handleEdit}
                                >
                                    {extendedMode == DetailsMode.EDIT ? "Save" : "Edit"}
                                </button>
                            )}

                            {extendedMode != DetailsMode.EDIT && (
                                <>
                                    <button
                                        className="join-item btn btn-accent w-40 h-10 z-40"
                                        onClick={handleDownload}
                                        disabled={!selectedBody}
                                    >
                                        {"Download " + (selectedBody ? selectedBody : "-")}
                                    </button>

                                    <button
                                        className="join-item btn btn-primary w-40 h-10 z-40"
                                        onClick={handleDownloadAll}
                                    >
                                        Download all (zip)
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                <div className="flex w-screen h-screen">
                    {(isCadOwned(cad.id) ||
                        isMod() ||
                        cad.price == null ||
                        cad.price == 0 ||
                        (user && cad.userId == user.id)) && (
                        <ModelView
                            isMobile={false}
                            cad={modifedCad}
                            newBodySelectedBody={newBodySelectedBody}
                            switchMode={handleProjectModeSwitch}
                            partMode={!projectMode}
                            rotation={rotation}
                            setRotationCallback={setRotationCallback}
                            selectedBodyInfo={selectedBody ? selectedBodyInfo : null}
                            setSelectedBodyInfo={setSelectedBodyInfo}
                            onStartLoading={onStartLoading}
                            onFinishLoading={onFinishLoading}
                            onError={onError}
                            gifMode={gifMode}
                            capture={capture}
                            captureReady={handleCaptureReady}
                            color={color}
                            description={description}
                            title={modifiedTitle}
                            tags={tags}
                            author={author}
                        ></ModelView>
                    )}
                    {!(
                        isCadOwned(cad.id) ||
                        isMod() ||
                        cad.price == null ||
                        cad.price == 0 ||
                        (user && cad.userId == user.id)
                    ) && (
                        <div className="flex h-screen content-center items-center w-screen">
                            <div className="m-auto bg-base-200 rounded-xl">
                                <div className="text-base mb-2 mt-2 ml-4">
                                    Animated preview - models unlock after purchase.
                                </div>
                                <video loop muted autoPlay>
                                    <source
                                        src={`${REACT_APP_API_URL || "http://localhost"}/${cad.thumbnailUrl}`}
                                        type="video/mp4"
                                    ></source>
                                </video>
                            </div>
                        </div>
                    )}
                </div>
                <div className="fixed top-0 left-5 w-96 z-100">
                    {extendedMode == DetailsMode.PRESENT && (
                        <Properties
                            isMobile={isMobile}
                            properties={modifiedProperties}
                            handleRender={handleRender}
                            handlePropertyChange={handlePopertyChange}
                            handleDefault={handleDefault}
                            hidden={hiddenProperties}
                            setHidden={hideShowProperties}
                            canRender={
                                isCadOwned(cad.id) ||
                                isMod() ||
                                cad.price == null ||
                                cad.price == 0 ||
                                (user != null && cad.userId == user.id)
                            }
                        ></Properties>
                    )}
                    {extendedMode != DetailsMode.PRESENT && (
                        <SetupProperties
                            properties={modifiedProperties}
                            addedProperties={addedProperties}
                            setAddedProperties={setAddedProperties}
                            hidden={hiddenProperties}
                            setHidden={hideShowProperties}
                        ></SetupProperties>
                    )}
                </div>
                <div className="fixed top-[-3px] right-5 w-96">
                    {extendedMode == DetailsMode.PRESENT && (
                        <Description
                            cad={cad}
                            prints={prints}
                            selectedBodyInfo={selectedBodyInfo}
                            bodyDescription={cad.bodiesDescription.find(
                                (description) => selectedBody == description.bodyName,
                            )}
                            selectedBody={selectedBody}
                            hidden={hiddenDescription}
                            setHidden={hideShowDescription}
                            removePrint={handleRemovePrint}
                            openImageModel={openImageModel}
                        ></Description>
                    )}
                    {extendedMode != DetailsMode.PRESENT && (
                        <SetupDescription
                            cad={modifedCad}
                            bodyDescription={modifedCad.bodiesDescription.find(
                                (description) => selectedBody == description.bodyName,
                            )}
                            selectedBody={selectedBody}
                            description={description}
                            bodyDescriptions={bodyDescriptions}
                            tags={tags}
                            setBodyDescriptions={setBodyDescriptions}
                            setDescription={setDescription}
                            color={color}
                            setColor={setColor}
                            url={url}
                            setUrl={setUrl}
                            setTags={setTags}
                            price={price}
                            setPrice={setPrice}
                            hidden={hiddenDescription}
                            setHidden={hideShowDescription}
                        ></SetupDescription>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <dialog id="ikijka" className="modal">
                    <div className="modal-box">
                        <h3 className="font-bold">Add Print</h3>
                        <div className="modal-action">
                            <div className="inline bg-transparent w-full h-full">
                                <div className="rating">
                                    <input
                                        type="radio"
                                        name="rating-4"
                                        className="mask mask-star-2 bg-accent"
                                        onClick={() => setPrintRating(1)}
                                        checked={rating == 1}
                                    />
                                    <input
                                        type="radio"
                                        name="rating-4"
                                        className="mask mask-star-2 bg-accent"
                                        onClick={() => setPrintRating(2)}
                                        checked={rating == 2}
                                    />
                                    <input
                                        type="radio"
                                        name="rating-4"
                                        className="mask mask-star-2 bg-accent"
                                        onClick={() => setPrintRating(3)}
                                        checked={rating == 3}
                                    />
                                    <input
                                        type="radio"
                                        name="rating-4"
                                        className="mask mask-star-2 bg-accent"
                                        onClick={() => setPrintRating(4)}
                                        checked={rating == 4}
                                    />
                                    <input
                                        type="radio"
                                        name="rating-4"
                                        className="mask mask-star-2 bg-accent"
                                        onClick={() => setPrintRating(5)}
                                        checked={rating == 5}
                                    />
                                </div>
                                <label className="form-control w-full mt-4">
                                    <div className="label mb-[-5px]">
                                        <span className="label-text">Message</span>
                                    </div>
                                    <textarea
                                        className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                        placeholder=""
                                        value={printComment ? printComment : ""}
                                        onChange={onPrintMessageChange}
                                        onBlur={onPrintMessageChange}
                                    ></textarea>
                                </label>
                                <div className="flex justify-center mb-4">
                                    <label className="form-control w-80 max-w-xs mt-5">
                                        <div className="label">
                                            <span className="label-text">Photos</span>
                                        </div>
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file1"
                                            onChange={handleFileChange1}
                                        />
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file2"
                                            onChange={handleFileChange2}
                                        />
                                        <input
                                            type="file"
                                            className={"file-input file-input-bordered file-input-sm mb-4"}
                                            id="file3"
                                            onChange={handleFileChange3}
                                        />
                                    </label>
                                </div>
                                <form method="dialog">
                                    <button className="btn" onClick={handlePrintUpload}>
                                        Add
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
                <dialog id="add_report" className="modal">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Report</h3>
                        <div className="modal-action">
                            <div className="inline bg-transparent w-full h-full mx-10 my-4">
                                <div className="text-left font-bold">Reason:</div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.TERMS)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.TERMS)
                                            }}
                                            checked={reportAbuseType == AbuseType.TERMS}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.COPYRIGHT)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.COPYRIGHT)
                                            }}
                                            checked={reportAbuseType == AbuseType.COPYRIGHT}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.QUALITY)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.QUALITY)
                                            }}
                                            checked={reportAbuseType == AbuseType.QUALITY}
                                        />
                                    </label>
                                    <label className="label cursor-pointer">
                                        <span className="label-text">
                                            {abuseTypeToDescription(AbuseType.OTHER)}
                                        </span>
                                        <input
                                            type="radio"
                                            name="radio-10"
                                            className="radio checked:bg-primary"
                                            onChange={() => {
                                                setReportAbuseType(AbuseType.OTHER)
                                            }}
                                            checked={reportAbuseType == AbuseType.OTHER}
                                        />
                                    </label>
                                </div>

                                {reportAbuseType == AbuseType.COPYRIGHT && (
                                    <input
                                        type="text"
                                        className="input focus:outline-none input-bordered input w-full mt-4"
                                        placeholder="Url (optional)"
                                        value={reportAbuseUrl ?? ""}
                                        onChange={onAbuseUrlChange}
                                        onBlur={onAbuseUrlChange}
                                    />
                                )}

                                <label className="form-control w-full mt-4">
                                    <div className="label mb-[-5px]">
                                        <span className="label-text">Description</span>
                                    </div>
                                    <textarea
                                        className="textarea pb-32 mb-6 h-42 bg-base-200 focus:outline-none"
                                        placeholder=""
                                        value={reportAbuseMessage}
                                        onChange={onAbuseMessageChange}
                                        onBlur={onAbuseMessageChange}
                                    ></textarea>
                                </label>
                                <form method="dialog">
                                    <button className="btn" onClick={handleAbuseReportUpload}>
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
                <dialog id="remove_cad" className="modal">
                    <div className="modal-box w-64">
                        <h3 className="font-bold text-lg">Remove model?</h3>
                        <div className="modal-action w-full">
                            <form method="dialog w-full">
                                <div className="w-full">
                                    <button className="btn btn-outline mr-10">Cancel</button>
                                    <button className="btn btn-error" onClick={handleDelete}>
                                        Remove
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </dialog>
                <dialog id="buy_cad" className="modal">
                    <div className="modal-box w-64">
                        <h3 className="font-bold text-lg">
                            Buy {cad.title} model for ${cad.price}?
                        </h3>
                        <p className="text-sm mb-2">
                            All 3D models purchased and downloaded through the printedmachines.com platform
                            are digital products. Once the user has initiated the download of the product, it
                            is non-refundable with the exception when a product is determined to be defective
                            or does not correspond to the description provided.
                        </p>
                        <a className="link" href="/refund" target="_blank" rel="noopener noreferrer">
                            Read Refunds policy
                        </a>
                        <form method="dialog">
                            <div className="modal-action w-full flex justify-between">
                                <button className="btn btn-outline w-24">Cancel</button>
                                <button className="btn btn-accent w-24" onClick={handleBuyCad}>
                                    Buy
                                </button>
                            </div>
                        </form>
                    </div>
                </dialog>
                <dialog id="print_image" className="modal">
                    <div className="modal-box max-w-full max-h-full">
                        <div className="carousel carousel-center rounded-box">
                            {focusedPrintsImage && (
                                <div key={"print_image_magnified_"} className="carousel-item">
                                    <img
                                        src={`${REACT_APP_API_URL || "http://localhost"}/${focusedPrintsImage}`}
                                    />
                                </div>
                            )}
                            {printsImages
                                .filter((printUrl) => !(printUrl == focusedPrintsImage))
                                .map((printUrl, index) => {
                                    return (
                                        <div key={"print_image_magnified_" + index} className="carousel-item">
                                            <img
                                                src={`${REACT_APP_API_URL || "http://localhost"}/${printUrl}`}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </dialog>
                <dialog id="remove_print" className="modal">
                    <div className="modal-box w-64">
                        <h3 className="font-bold text-lg">Remove print</h3>
                        <div className="modal-action w-full">
                            <form method="dialog w-full flex justify-between">
                                <button className="btn btn-outline">Cancel</button>
                                <button className="btn btn-error" onClick={handleDelete}>
                                    Remove
                                </button>
                            </form>
                        </div>
                    </div>
                </dialog>
                <dialog id="degimi" className="modal">
                    <div className="modal-box w-80 h-40">
                        <h3 className="font-bold text-lg">Custom Bed Size</h3>
                        <div className="modal-action">
                            <div className="inline bg-transparent">
                                <input
                                    type="text"
                                    className="input focus:outline-none input-bordered input w-20 text-right"
                                    value={customX}
                                    onChange={onXValueChange}
                                    onBlur={onBlurX}
                                />
                                <div className="inline mx-3">X</div>
                                <input
                                    type="text"
                                    className="input focus:outline-none input-bordered input w-20 text-right"
                                    value={customY}
                                    onChange={onYValueChange}
                                    onBlur={onBlurY}
                                />
                            </div>
                            <form method="dialog">
                                <button className="btn" onClick={setCustomBoardSize}>
                                    Set
                                </button>
                            </form>
                        </div>
                    </div>
                </dialog>
                {isLoading && (
                    <div className="flex w-screen h-screen bg-transparent z-100 absolute top-0 left-0">
                        <div className="m-auto bg-transparent">
                            <div className="bg-base-200 py-3 px-4 rounded-lg">
                                <span className="mt-1 loading loading-bars loading-lg"></span>
                            </div>
                        </div>
                    </div>
                )}

                {mobileTab == MobileDetailTab.MODEL && (
                    <div className="z-40 w-full h-full">
                        <div className="absolute top-16 right-4">
                            {cad.price &&
                                !isCadOwned(cad.id) &&
                                !isMod() &&
                                !(user && cad.userId == user.id) &&
                                extendedMode == DetailsMode.PRESENT && (
                                    <div className="flex z-40 justify-end mt-4">
                                        <button
                                            className="btn btn-accent h-10 mt-16 z-40"
                                            disabled={!user}
                                            onClick={handleOpenBuyModal}
                                        >
                                            {"Buy $" + cad.price}
                                        </button>
                                    </div>
                                )}
                            {extendedMode != DetailsMode.EDIT &&
                                (isCadOwned(cad.id) ||
                                    (user && cad.userId == user.id) ||
                                    !cad.price ||
                                    cad.price == 0) && (
                                    <>
                                        <button
                                            className="join-item btn btn-primary mt-20 h-10 z-40"
                                            onClick={handleDownloadAll}
                                        >
                                            <svg
                                                className="m-[-5px]"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195"
                                                    stroke="#1C274C"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                                <path
                                                    d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5"
                                                    stroke="#1C274C"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </>
                                )}
                        </div>
                    </div>
                )}

                {mobileTab == MobileDetailTab.MODEL && (
                    <div>
                        <div className="absolute top-0 left-0 w-full">
                            <div>
                                <button
                                    className="btn btn-outline h-6 mt-4 ml-4"
                                    onClick={onBackButtonClicked}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 ml-[0px] z-0 w-[24px]"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                    >
                                        <path
                                            d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                            fillRule="nonzero"
                                        />
                                    </svg>
                                </button>
                                <div className="mt-[-45px] mb-8 pl-4 w-64 m-auto mb-2 text-center">
                                    <div className="inline text-wrap">
                                        {cad.title} {"by "}
                                    </div>
                                    <a className="link" href={`/user/${cad.author}`}>
                                        {cad.author}
                                    </a>
                                </div>
                            </div>
                            <div role="tablist" className="tabs tabs-boxed m-auto w-80 z-40">
                                <a
                                    role="tab"
                                    className={"tab tab-active"}
                                    onClick={() => setMobileTab(MobileDetailTab.MODEL)}
                                >
                                    Model
                                </a>
                                <a
                                    role="tab"
                                    className={"tab"}
                                    onClick={() => setMobileTab(MobileDetailTab.DETAILS)}
                                >
                                    Details
                                </a>
                                <a
                                    role="tab"
                                    className={"tab " + (cad.properties.length == 0 ? "tab-disabled" : "")}
                                    onClick={() => {
                                        if (cad.properties.length > 0) {
                                            setMobileTab(MobileDetailTab.PROPERTIES)
                                        }
                                    }}
                                >
                                    Properties
                                </a>
                            </div>
                        </div>

                        <div className="w-screen h-screen z-10">
                            {(isCadOwned(cad.id) ||
                                isMod() ||
                                cad.price == null ||
                                cad.price == 0 ||
                                (user && cad.userId == user.id)) && (
                                <ModelView
                                    isMobile={isMobile}
                                    cad={modifedCad}
                                    newBodySelectedBody={newBodySelectedBody}
                                    switchMode={handleProjectModeSwitch}
                                    partMode={!projectMode}
                                    rotation={rotation}
                                    setRotationCallback={setRotationCallback}
                                    selectedBodyInfo={selectedBody ? selectedBodyInfo : null}
                                    setSelectedBodyInfo={setSelectedBodyInfo}
                                    onStartLoading={onStartLoading}
                                    onFinishLoading={onFinishLoading}
                                    onError={onError}
                                    gifMode={gifMode}
                                    capture={capture}
                                    captureReady={handleCaptureReady}
                                    color={color}
                                    description={description}
                                    title={modifiedTitle}
                                    tags={tags}
                                    author={author}
                                ></ModelView>
                            )}
                            {!(
                                isCadOwned(cad.id) ||
                                isMod() ||
                                cad.price == null ||
                                cad.price == 0 ||
                                (user && cad.userId == user.id)
                            ) && (
                                <div className="flex h-screen content-center items-center w-screen mt-10">
                                    <div className="m-auto bg-base-200 rounded-xl">
                                        <div className="text-base mb-2 mt-2 ml-4">
                                            Animated preview - models unlock after purchase.
                                        </div>
                                        <video loop muted autoPlay>
                                            <source
                                                src={`${REACT_APP_API_URL || "http://localhost"}/${cad.thumbnailUrl}`}
                                                type="video/mp4"
                                            ></source>
                                        </video>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {mobileTab == MobileDetailTab.PROPERTIES && (
                    <div>
                        <div className="absolute top-0 left-0 w-full">
                            <div>
                                <button
                                    className="btn btn-outline h-6 mt-4 ml-4"
                                    onClick={onBackButtonClicked}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 ml-[0px] z-0 w-[24px]"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                    >
                                        <path
                                            d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                            fillRule="nonzero"
                                        />
                                    </svg>
                                </button>
                                <div className="mt-[-45px] mb-8 pl-4 w-64 m-auto mb-2 text-center">
                                    <div className="inline text-wrap">
                                        {cad.title} {"by "}
                                    </div>
                                    <a className="link" href={`/user/${cad.author}`}>
                                        {cad.author}
                                    </a>
                                </div>
                            </div>
                            <div role="tablist" className="tabs tabs-boxed m-auto w-80 z-40">
                                <a
                                    role="tab"
                                    className={"tab"}
                                    onClick={() => setMobileTab(MobileDetailTab.MODEL)}
                                >
                                    Model
                                </a>
                                <a
                                    role="tab"
                                    className={"tab"}
                                    onClick={() => setMobileTab(MobileDetailTab.DETAILS)}
                                >
                                    Details
                                </a>
                                <a
                                    role="tab"
                                    className={"tab tab-active"}
                                    onClick={() => setMobileTab(MobileDetailTab.PROPERTIES)}
                                >
                                    Properties
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 w-full rounded">
                            {extendedMode == DetailsMode.PRESENT && (
                                <Properties
                                    isMobile={isMobile}
                                    properties={modifiedProperties}
                                    handleRender={handleRender}
                                    handlePropertyChange={handlePopertyChange}
                                    handleDefault={handleDefault}
                                    hidden={hiddenProperties}
                                    setHidden={hideShowProperties}
                                    canRender={
                                        isCadOwned(cad.id) ||
                                        isMod() ||
                                        cad.price == null ||
                                        cad.price == 0 ||
                                        (user != null && cad.userId == user.id)
                                    }
                                ></Properties>
                            )}
                            {extendedMode != DetailsMode.PRESENT && (
                                <SetupProperties
                                    properties={modifiedProperties}
                                    addedProperties={addedProperties}
                                    setAddedProperties={setAddedProperties}
                                    hidden={hiddenProperties}
                                    setHidden={hideShowProperties}
                                ></SetupProperties>
                            )}
                        </div>
                    </div>
                )}

                {mobileTab == MobileDetailTab.DETAILS && (
                    <div>
                        <div className="absolute top-0 left-0 w-full">
                            <div>
                                <button
                                    className="btn btn-outline h-6 mt-4 ml-4"
                                    onClick={onBackButtonClicked}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 ml-[0px] z-0 w-[24px]"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                    >
                                        <path
                                            d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                            fillRule="nonzero"
                                        />
                                    </svg>
                                </button>
                                <div className="mt-[-45px] mb-8 pl-4 w-64 m-auto mb-2 text-center">
                                    <div className="inline text-wrap">
                                        {cad.title} {"by "}
                                    </div>
                                    <a className="link" href={`/user/${cad.author}`}>
                                        {cad.author}
                                    </a>
                                </div>
                            </div>
                            <div role="tablist" className="tabs tabs-boxed m-auto w-80 z-40">
                                <a
                                    role="tab"
                                    className={"tab"}
                                    onClick={() => setMobileTab(MobileDetailTab.MODEL)}
                                >
                                    Model
                                </a>
                                <a
                                    role="tab"
                                    className={"tab tab-active"}
                                    onClick={() => setMobileTab(MobileDetailTab.DETAILS)}
                                >
                                    Details
                                </a>
                                <a
                                    role="tab"
                                    className={"tab " + (cad.properties.length == 0 ? "tab-disabled" : "")}
                                    onClick={() => {
                                        if (cad.properties.length > 0) {
                                            setMobileTab(MobileDetailTab.PROPERTIES)
                                        }
                                    }}
                                >
                                    Properties
                                </a>
                            </div>
                        </div>
                        <div className="mt-40">
                            {extendedMode == DetailsMode.PRESENT && (
                                <Description
                                    isMobile={isMobile}
                                    cad={cad}
                                    prints={prints}
                                    selectedBodyInfo={selectedBodyInfo}
                                    bodyDescription={cad.bodiesDescription.find(
                                        (description) => selectedBody == description.bodyName,
                                    )}
                                    selectedBody={selectedBody}
                                    hidden={hiddenDescription}
                                    setHidden={hideShowDescription}
                                    removePrint={handleRemovePrint}
                                    openImageModel={openImageModel}
                                ></Description>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

// export default Details
