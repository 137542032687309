import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, Property, Print, RenderParameters, BodyDescription, useApi } from "../model/api"

import { PropertyItem, ModifiedProperty } from "./PropertyItem"
import PrintItem from "./Print"

type DescriptionProps = {
    isMobile?: boolean
    cad: Cad
    prints: Print[]
    selectedBody: any
    selectedBodyInfo: any
    bodyDescription: BodyDescription | undefined
    hidden: boolean
    setHidden: any
    removePrint: (id: string) => void
    openImageModel: (image: string, images?: string[]) => void
}

function formatDate(isoString: string) {
    const date = new Date(isoString)
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
}

function Description(props: DescriptionProps) {
    const {
        isMobile,
        cad,
        selectedBodyInfo,
        bodyDescription,
        selectedBody,
        hidden,
        setHidden,
        prints,
        removePrint,
        openImageModel,
    } = props

    const { isCadOwned, user, isMod } = useApi()

    const [userPrint, setUserPrint] = useState<Print | undefined>(undefined)
    const [otherUserprints, setOtherUserprints] = useState<Print[]>([])

    useEffect(() => {
        setUserPrint(prints.find((print) => user && print.userId == user.id))
        setOtherUserprints(prints.filter((print) => !(user && print.userId == user.id)))
    }, [prints, user])

    return (
        <div>
            {!isMobile && (
                <div
                    className={
                        "pt-8 transition-transform duration-300" +
                        (hidden ? " translate-x-[95%] hover:translate-x-[90%] hover:transition-scale" : "")
                    }
                    onClick={hidden ? setHidden : () => {}}
                >
                    <div className="w-full z-10 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                        <div className="font-bold text-3xl pb-5 pt-2 mb-[-40px] ml-4 mt-12 text-left">
                            Description
                            <button
                                className="btn btn-ghost text-base ml-[-10px] hover:bg-transparent"
                                onClick={setHidden}
                            >
                                <svg
                                    className="h-6 w-6 translate-y-1"
                                    viewBox="0 0 48 48"
                                    stroke="none"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.957,33.214L7.171,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.953-5.159-7.957-9.214
		                                L40.829,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.953,29.159,11.957,33.214z M16,24
    		                            c0-4.418,3.582-8,8-8c1.483,0,2.867,0.411,4.058,1.114l-3.035,3.035C24.694,20.062,24.356,20,24,20c-2.206,0-4,1.794-4,4
	    	                            c0,0.356,0.062,0.694,0.149,1.023l-3.035,3.035C16.411,26.867,16,25.483,16,24z M32,24c0,4.418-3.582,8-8,8
		                                c-1.483,0-2.867-0.411-4.058-1.114l3.035-3.035C23.306,27.938,23.644,28,24,28c2.206,0,4-1.794,4-4
		                                c0-0.356-0.062-0.694-0.149-1.023l3.035-3.035C31.589,21.133,32,22.517,32,24z"
                                    />
                                </svg>
                            </button>
                            <div
                                className="tooltip tooltip-left mt-[4px] ml-[95px] absolute"
                                data-tip={"Report T&C, copyright violation"}
                            >
                                <a
                                    className="link font-normal text-base"
                                    onClick={() => {
                                        if (!document) {
                                            return
                                        }
                                        const element = document.getElementById("add_report")
                                        if (!element) {
                                            return
                                        }
                                        ;(element as any).showModal()
                                    }}
                                >
                                    Report
                                </a>
                            </div>
                        </div>
                        {}
                        <div className="bg-base-300/80 rounded-box pt-8 overflow-y-auto max-h-[72vh]">
                            <div className="w-full flex mb-[-30px]">
                                <div className="rating ml-4 bg-base-100 p-1 rounded-xl">
                                    {[1, 2, 3, 4, 5].map((value, index, array) => {
                                        return (
                                            <input
                                                readOnly
                                                key={"description_rating_key_" + value + "__" + index}
                                                type="radio"
                                                name={"rating_description-" + array.length}
                                                className={
                                                    "mb-1 mask mask-star-2 " +
                                                    (cad.ratingSum > 0 ? "bg-accent" : "bg-neutral")
                                                }
                                                checked={
                                                    cad.ratingNumber > 0 &&
                                                    cad.ratingSum / cad.ratingNumber >= value
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            {(!cad.price || cad.price == 0) && (
                                <div className="flex w-full justify-end">
                                    <a
                                        className="link text-sm mr-4"
                                        href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        License: CC BY-NC-SA
                                    </a>
                                </div>
                            )}
                            <div className="label-text-alt text-left text-base px-4 pb-6 mt-14">
                                {cad.description}
                            </div>

                            <div className="mb-5 px-4 text-left">
                                {cad.tags.map((tag: string, index: number) => {
                                    return (
                                        <div key={"item_tag_" + index} className="badge ml-1 badge-outline">
                                            {tag}
                                        </div>
                                    )
                                })}
                            </div>
                            {selectedBodyInfo && selectedBody && (
                                <div className="mb-5">
                                    <div className="label-text-alt text-xl font-bold text-left px-4 mt-2">
                                        {selectedBody}
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4 mt-2">
                                        Size: {Math.round(selectedBodyInfo.dims.x)}
                                        mm x {Math.round(selectedBodyInfo.dims.y)}mm x{" "}
                                        {Math.round(selectedBodyInfo.dims.z)}
                                        mm
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4">
                                        Area: {Math.round(selectedBodyInfo.area)} mm<sup>2</sup>
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4 mb-4">
                                        Volume: {Math.round(selectedBodyInfo.volume)} mm<sup>3</sup>
                                    </div>
                                    <div className="label-text-alt text-md text-left text-base px-4 mt-4 mb-4">
                                        {bodyDescription ? bodyDescription.description : ""}
                                    </div>
                                </div>
                            )}

                            <div className="w-full text-left font-bold text-xl">
                                <div className="ml-4 inline">User Prints:</div>

                                <div
                                    className="tooltip tooltip-bottom"
                                    data-tip={user ? "Add yout print of this model" : "Login to add print"}
                                >
                                    <button
                                        className="btn btn-outline btn-sm mb-4 ml-4"
                                        disabled={
                                            !(
                                                (isCadOwned(cad.id) ||
                                                    cad.price == null ||
                                                    cad.price == 0 ||
                                                    (user != null && cad.userId == user.id)) &&
                                                !userPrint
                                            )
                                        }
                                        onClick={() => {
                                            if (!document) {
                                                return
                                            }
                                            const element = document.getElementById("ikijka")
                                            if (!element) {
                                                return
                                            }
                                            ;(element as any).showModal()
                                        }}
                                    >
                                        Add Print
                                    </button>
                                </div>
                            </div>
                            <div className="mb-4">
                                {userPrint && (
                                    <PrintItem
                                        isMobile={false}
                                        print={userPrint}
                                        isUserPrint={true}
                                        removePrint={removePrint}
                                        openImageModel={openImageModel}
                                    ></PrintItem>
                                )}
                                {otherUserprints &&
                                    otherUserprints.map((print, index) => {
                                        return (
                                            <PrintItem
                                                isMobile={false}
                                                key={"prints_" + index}
                                                print={print}
                                                isUserPrint={false}
                                                removePrint={removePrint}
                                                openImageModel={openImageModel}
                                            ></PrintItem>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isMobile && (
                <div>
                    <div className="w-full h-full">
                        <div className="bg-base-300/80 overflow-y-auto w-full">
                            <div className="w-full flex justify-between">
                                <div className="mt-4 ml-4 rating bg-base-100 p-1 rounded-xl">
                                    {[1, 2, 3, 4, 5].map((value, index, array) => {
                                        return (
                                            <input
                                                readOnly
                                                key={"rating_key_" + value + "__" + index}
                                                type="radio"
                                                name={"mobile-rating-" + array.length}
                                                className={
                                                    "mb-1 mask mask-star-2 " +
                                                    (cad.ratingSum > 0 ? "bg-accent" : "bg-neutral")
                                                }
                                                checked={
                                                    cad.ratingNumber > 0 &&
                                                    cad.ratingSum / cad.ratingNumber >= value
                                                }
                                            />
                                        )
                                    })}
                                </div>
                                <div className="mt-5 mr-4">
                                    <a
                                        className="link font-normal text-base"
                                        onClick={() => {
                                            if (!document) {
                                                return
                                            }
                                            const element = document.getElementById("add_report")
                                            if (!element) {
                                                return
                                            }
                                            ;(element as any).showModal()
                                        }}
                                    >
                                        Report
                                    </a>
                                </div>
                            </div>
                            {(!cad.price || cad.price == 0) && (
                                <div className="flex w-full justify-end mt-4">
                                    <a
                                        className="link text-sm mr-4"
                                        href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        License: CC BY-NC-SA
                                    </a>
                                </div>
                            )}
                            <div className="label-text-alt text-left text-base px-4 pb-6 mt-8">
                                {cad.description}
                            </div>
                            <div className="mb-5 px-4 text-left">
                                {cad.tags.map((tag: string, index: number) => {
                                    return (
                                        <div key={"item_tag_" + index} className="badge ml-1 badge-outline">
                                            {tag}
                                        </div>
                                    )
                                })}
                            </div>
                            {selectedBodyInfo && selectedBody && (
                                <div className="mb-5">
                                    <div className="label-text-alt text-xl font-bold text-left px-4 mt-2">
                                        {selectedBody}
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4 mt-2">
                                        Size: {Math.round(selectedBodyInfo.dims.x)}
                                        mm x {Math.round(selectedBodyInfo.dims.y)}mm x{" "}
                                        {Math.round(selectedBodyInfo.dims.z)}
                                        mm
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4">
                                        Area: {Math.round(selectedBodyInfo.area)} mm<sup>2</sup>
                                    </div>
                                    <div className="label-text-alt text-xl text-left px-4 mb-4">
                                        Volume: {Math.round(selectedBodyInfo.volume)} mm<sup>3</sup>
                                    </div>
                                    <div className="label-text-alt text-md text-left text-base px-4 mt-4 mb-4">
                                        {bodyDescription ? bodyDescription.description : ""}
                                    </div>
                                </div>
                            )}

                            <div className="text-left ml-4 font-bold text-xl">
                                <div className="inline">User Prints:</div>
                                <button
                                    className="btn btn-outline btn-sm mb-4 ml-4"
                                    disabled={
                                        !(
                                            (isCadOwned(cad.id) ||
                                                cad.price == null ||
                                                cad.price == 0 ||
                                                (user != null && cad.userId == user.id)) &&
                                            !userPrint
                                        )
                                    }
                                    onClick={() => {
                                        if (!document) {
                                            return
                                        }
                                        const element = document.getElementById("ikijka")
                                        if (!element) {
                                            return
                                        }
                                        ;(element as any).showModal()
                                    }}
                                >
                                    Add Print
                                </button>
                            </div>
                            <div className="mb-4">
                                {userPrint && (
                                    <PrintItem
                                        isMobile={isMobile}
                                        print={userPrint}
                                        isUserPrint={true}
                                        removePrint={removePrint}
                                        openImageModel={openImageModel}
                                    ></PrintItem>
                                )}
                                {otherUserprints &&
                                    otherUserprints.map((print, index) => {
                                        return (
                                            <PrintItem
                                                isMobile={isMobile}
                                                key={"prints_" + index}
                                                print={print}
                                                isUserPrint={false}
                                                removePrint={removePrint}
                                                openImageModel={openImageModel}
                                            ></PrintItem>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Description
