import React, { useCallback, useEffect, useState, useContext } from "react"
import "./Details.css"

import { SettingsContext } from "../model/settings"
import { ApiContext, Cad, useApi } from "../model/api"

import STL from "./STL"
import { Rotation } from "./Details"
import GIFStl from "./GIFStl"

type ModifiedCad = Cad & { currentRenderHash?: string }

type ModelViewProps = {
    isMobile: boolean
    cad: ModifiedCad
    newBodySelectedBody: (body: string | null) => void
    switchMode: () => void
    partMode: boolean
    rotation: Rotation
    setRotationCallback: (newRotation: Rotation) => void
    selectedBodyInfo: any
    setSelectedBodyInfo: any
    onStartLoading: () => void
    onFinishLoading: () => void
    onError: (error: any) => void
    captureReady: (recording: Blob) => void
    gifMode: boolean
    capture: boolean
    color: any
    description?: string
    title?: string
    author?: string
    tags?: any
}

function ModelView(props: ModelViewProps) {
    const {
        isMobile,
        cad,
        newBodySelectedBody,
        switchMode,
        partMode,
        rotation,
        setRotationCallback,
        selectedBodyInfo,
        setSelectedBodyInfo,
        onStartLoading,
        onFinishLoading,
        onError,
        gifMode,
        capture,
        captureReady,
        color,
        description,
        title,
        author,
        tags,
    } = props

    const { currentBedSize } = useContext(SettingsContext)

    const { authHeaders, user, apiUrl } = useApi()

    const [selectedBody, setSelectedBody] = useState<string | null>(null)
    const [highlightedBody, setHighlightedBody] = useState<string | null>(null)

    const [convertedHeaders, setConvertedHeaders] = useState<any[] | null>(null)

    useEffect(() => {
        const headers = authHeaders()
        if (headers) {
            setConvertedHeaders(convertAuthHeaders(headers))
        }
    }, [authHeaders, user])

    const setHighlightedHoverBody = useCallback(
        (body: string) => {
            setHighlightedBody(body)
        },
        [cad, selectedBody],
    )

    useEffect(() => {
        const handleHashChange = () => {
            const hashParam = window.location.hash.substring(1)
            var body = null
            if (hashParam.length != 0) {
                body = hashParam.replace("item_", "")
            }
            setSelectedBody(body)
            newBodySelectedBody(body)
        }

        handleHashChange()
        window.addEventListener("hashchange", handleHashChange)
    }, [])

    const convertAuthHeaders = (headersDict: any) => {
        return Object.keys(headersDict).map((key) => {
            return {
                key: key,
                value: headersDict[key],
            }
        })
    }

    return (
        <div className="flex w-full h-full">
            <div className="w-full h-full">
                {!gifMode && (!user || (user && convertedHeaders)) && (
                    <STL
                        w={currentBedSize.x}
                        h={currentBedSize.y}
                        onFinishLoading={onFinishLoading}
                        onStartLoading={onStartLoading}
                        onError={onError}
                        baseUrl={
                            `${apiUrl}/renders/` +
                            cad.id +
                            "/" +
                            (cad.currentRenderHash || cad.renderHash) +
                            "/"
                        }
                        bodies={cad.bodies}
                        scene={cad.scene}
                        selectedBody={selectedBody}
                        setSelectedBody={(body: any) => {
                            setSelectedBody(body)
                            newBodySelectedBody(body)
                        }}
                        highlightedBody={highlightedBody}
                        setHighlightedBody={(body: string) => {
                            setHighlightedHoverBody(body)
                        }}
                        switchMode={switchMode}
                        partMode={partMode}
                        rotation={rotation}
                        setRotationCallback={setRotationCallback}
                        selectedBodyInfo={selectedBodyInfo}
                        setSelectedBodyInfo={setSelectedBodyInfo}
                        color={color}
                        customHeaders={convertedHeaders}
                    />
                )}
                {gifMode && (
                    <div className="flex h-screen">
                        <div className="m-auto">
                            <GIFStl
                                baseUrl={
                                    `${apiUrl}/renders/` +
                                    cad.id +
                                    "/" +
                                    (cad.currentRenderHash || cad.renderHash) +
                                    "/"
                                }
                                bodies={cad.bodies}
                                scene={cad.scene}
                                onFinishLoading={onFinishLoading}
                                onStartLoading={onStartLoading}
                                onError={onError}
                                capture={capture}
                                captureReady={captureReady}
                                color={color}
                                description={description || ""}
                                title={title || ""}
                                author={user?.displayName || ""}
                                tags={tags || []}
                            ></GIFStl>
                        </div>
                    </div>
                )}
            </div>
            <div className="absolute w-full bottom-6 overflow-y-scroll text-center scrollbar px-5 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                <div className={cad.bodies.length > 1 ? "join" : ""}>
                    {cad.bodies.map((bodyName, index) => {
                        if (bodyName == selectedBody) {
                            return (
                                <a
                                    key={`model_links_${bodyName}`}
                                    href={`#item_${bodyName}`}
                                    className="join-item font-bold bold btn btn-accent my-2"
                                >
                                    {bodyName}
                                </a>
                            )
                        } else if (bodyName == highlightedBody && !partMode) {
                            return (
                                <a
                                    key={`model_links_${bodyName}`}
                                    href={`#item_${bodyName}`}
                                    onMouseEnter={() => {
                                        setHighlightedHoverBody(bodyName)
                                    }}
                                    className={
                                        "join-item font-bold bold btn btn-primary hover:btn-primary my-2 " +
                                        (index + 1 >= cad.bodies.length ? "mr-4" : "")
                                    }
                                >
                                    {!isMobile && selectedBody && cad.bodies[index + 1] == selectedBody ? (
                                        <kbd className="kbd kbd-sm bg-transparent">,</kbd>
                                    ) : (
                                        ""
                                    )}
                                    {!isMobile && selectedBody && cad.bodies[index - 1] == selectedBody ? (
                                        <kbd className="kbd bg-transparent kbd-sm">.</kbd>
                                    ) : (
                                        ""
                                    )}
                                    {bodyName}
                                </a>
                            )
                        } else {
                            return (
                                <a
                                    key={`model_links_${bodyName}`}
                                    href={`#item_${bodyName}`}
                                    onMouseEnter={() => {
                                        setHighlightedHoverBody(bodyName)
                                    }}
                                    className={
                                        "join-item bold btn hover:btn-primary my-2 " +
                                        (index + 1 >= cad.bodies.length ? "mr-4" : "")
                                    }
                                >
                                    {!isMobile && selectedBody && cad.bodies[index + 1] == selectedBody ? (
                                        <kbd className="kbd kbd-sm bg-transparent">,</kbd>
                                    ) : (
                                        ""
                                    )}
                                    {!isMobile && selectedBody && cad.bodies[index - 1] == selectedBody ? (
                                        <kbd className="kbd kbd-sm bg-transparent">.</kbd>
                                    ) : (
                                        ""
                                    )}
                                    {bodyName}
                                </a>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default ModelView
