import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, Property } from "../model/api"

export type ModifiedProperty = Property & { currentValue?: string }

type SetupPropertyItemProps = {
    address: string
    name: string
    description: string
    readonly: boolean
    type?: string
    onAddressChange: (event: any) => void
    onNameChange: (event: any) => void
    onDescriptionChange: (event: any) => void
    onReadonlyChange: (event: any) => void
    onTypeChange: (event: any) => void
    onRemove: (event: any) => void
}

export function SetupPropertyItem(props: SetupPropertyItemProps) {
    const {
        address,
        name,
        description,
        readonly,
        type,
        onAddressChange,
        onNameChange,
        onReadonlyChange,
        onDescriptionChange,
        onRemove,
        onTypeChange,
    } = props

    return (
        <div className="px-2">
            <div className="label-text-alt text-left px-2 py-2">
                <div className="flex place-content-between">
                    <label className="form-control">
                        <div className="label mb-[-5px]">
                            <span className="label-text">
                                <dfn className="not-italic" title="Cell address in spreadsheet">
                                    Address
                                </dfn>
                            </span>
                        </div>
                        <input
                            type="text"
                            value={address}
                            className="input input-bordered input-sm w-7/12 focus:outline-none text-left"
                            readOnly={true}
                        />
                    </label>
                    <label className="form-control mr-2">
                        <div className="label mb-[-5px]">
                            <span className="label-text">Name</span>
                        </div>
                        <input
                            type="text"
                            value={name}
                            className="input input-bordered input-sm focus:outline-none"
                            onChange={onNameChange}
                            onBlur={onNameChange}
                        />
                    </label>
                    <label className="form-control mr-2">
                        <div className="label mb-[-5px]">
                            <span className="label-text">Readonly</span>
                        </div>
                        <input
                            type="checkbox"
                            className="toggle toggle-sm toggle-accent mt-1.5 ml-4"
                            checked={readonly}
                            onChange={onReadonlyChange}
                        />
                    </label>
                    <></>
                </div>
            </div>
            <label className="form-control ml-2">
                <div className="label mb-[-5px]">
                    <span className="label-text">Description</span>
                </div>
                <textarea
                    className="textarea pb-32 mb-6 mr-4 h-32 bg-base-200 focus:outline-none overflow-hidden"
                    placeholder=""
                    value={description}
                    onChange={onDescriptionChange}
                    onBlur={onDescriptionChange}
                ></textarea>
            </label>
            <div className="flex justify-between">
                <div className="join ml-3">
                    <button
                        className={
                            "btn join-item btn-sm mt-[-10px] " +
                            (type == "mm" || !type ? "btn-primary" : "btn-outline")
                        }
                        onClick={onTypeChange}
                    >
                        MM
                    </button>
                    <button
                        className={
                            "btn join-item btn-sm mt-[-10px] " +
                            (type == "qty" ? "btn-primary" : "btn-outline")
                        }
                        onClick={onTypeChange}
                    >
                        QTY
                    </button>
                </div>
                <button className="btn btn-error btn-sm mt-[-10px] mr-4" onClick={onRemove}>
                    Remove
                </button>
            </div>
        </div>
    )
}
