import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, Property, Print, RenderParameters, BodyDescription, useApi } from "../model/api"

import { PropertyItem, ModifiedProperty } from "./PropertyItem"

type PrintProps = {
    isMobile: boolean
    print: Print
    isUserPrint: boolean
    removePrint: (id: string) => void
    openImageModel: (image: string, images?: string[]) => void
}

function formatDate(isoString: string) {
    const date = new Date(isoString)
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
}

function PrintItem(props: PrintProps) {
    const { REACT_APP_API_URL } = process.env

    const { isMobile, print, isUserPrint, removePrint, openImageModel } = props

    const { user, isMod } = useApi()

    useEffect(() => {}, [])

    return (
        <div className="rounded-xl bg-base-100 m-4 p-4 ">
            <div className="text-left flex justify-between">
                <div className="rating bg-base-100 rounded-xl">
                    {[1, 2, 3, 4, 5].map((value, index, array) => {
                        return (
                            <input
                                disabled
                                key={"rating_key_" + value}
                                type="radio"
                                name={"rating-" + array.length}
                                className={
                                    "mb-1 mask mask-star-2 " + (print.rating > 0 ? "bg-accent" : "bg-neutral")
                                }
                                checked={print.rating >= value}
                            />
                        )
                    })}
                </div>
                <div className="">{print.userDisplayName}</div>
            </div>
            <div className="text-left mt-4 mb-8">{print.comment}</div>

            <div className="text-left mb-2">Photos:</div>
            {print.image?.map((printImageLink, index) => {
                return (
                    <img
                        className="w-full h-full mb-4"
                        key={"print_image_key_" + index}
                        onClick={() => !isMobile && openImageModel(printImageLink, print.image)}
                        src={`${REACT_APP_API_URL || "http://localhost"}/${printImageLink}`}
                    ></img>
                )
            })}
            {isUserPrint && (
                <button className="btn btn-error btn-sm" onClick={() => removePrint(print.id)}>
                    Remove
                </button>
            )}
        </div>
    )
}

export default PrintItem
