import React, { useCallback, useEffect, useState } from "react"
import "./Details.css"
import { Cad, BodyDescription, useApi } from "../model/api"
import { HexColorPicker } from "react-colorful"

type SetupDescriptionProps = {
    cad: Cad
    selectedBody: any
    bodyDescription: BodyDescription | undefined
    description: string
    bodyDescriptions: any
    tags: string[]
    setDescription: any
    setTags: any
    setBodyDescriptions: any
    price: number
    setPrice: any
    color: any
    setColor: any
    url: any
    setUrl: any
    hidden: boolean
    setHidden: any
}

function SetupDescription(props: SetupDescriptionProps) {
    const {
        cad,
        selectedBody,
        description,
        bodyDescriptions,
        tags,
        setDescription,
        setTags,
        setBodyDescriptions,
        setColor,
        color,
        url,
        setUrl,
        setHidden,
        hidden,
        price,
        setPrice,
    } = props

    const [tag, setTag] = useState<string>("")

    const [priceInputValue, setPriceInputValue] = useState<string>(price.toString())

    const { user } = useApi()

    const onDescriptionValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 3072) {
                setDescription(value)
            }
        },
        [cad],
    )

    const onDescriptionBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 3072) {
                setDescription(value)
            }
        },
        [cad],
    )

    const onTagValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 24) {
                setTag(value)
            }
        },
        [cad],
    )

    const onTagBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 24) {
                setTag(value)
            }
        },
        [cad],
    )

    const onUrlValueChange = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 500) {
                setUrl(value)
            }
        },
        [cad],
    )

    const onUrlBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length <= 500) {
                setUrl(value)
            }
        },
        [cad],
    )

    const addTagHandle = useCallback(
        (event: any) => {
            if (tag.length < 3 || tags.includes(tag) || tag.length > 24) {
                return
            }
            const newTags = structuredClone(tags)
            newTags.push(tag)
            setTags(newTags)
            setTag("")
        },
        [cad, tags, tag],
    )

    const onBodyDescriptionValueChange = useCallback(
        (event: any) => {
            const newDescriptions = structuredClone(bodyDescriptions)
            const value = event.target.value
            if (value.length <= 3072) {
                newDescriptions[selectedBody] = value
                setBodyDescriptions(newDescriptions)
            }
        },
        [cad, selectedBody, bodyDescriptions],
    )

    const onBodyDescriptionBlur = useCallback(
        (event: any) => {
            const newDescriptions = structuredClone(bodyDescriptions)
            const value = event.target.value
            if (value.length <= 3072) {
                newDescriptions[selectedBody] = value
                setBodyDescriptions(newDescriptions)
            }
        },
        [cad, selectedBody, bodyDescriptions],
    )

    const onPriceChange = useCallback(
        (event: any) => {
            const value = event.target.value
            setPriceInputValue(value)
        },
        [cad, priceInputValue],
    )

    const onPriceBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            const number = parseFloat(value)
            if (number != null && number != Number.NaN && number <= 1000) {
                const truncatedNumber = Math.trunc(number * 100) / 100
                setPriceInputValue(truncatedNumber.toString())
                setPrice(truncatedNumber.toString())
            } else {
                setPriceInputValue(price.toString())
                setPrice(price)
            }
        },
        [cad, setPrice],
    )

    return (
        <div>
            <div
                className={
                    "pt-8 transition-transform duration-300" +
                    (hidden ? " translate-x-[95%] hover:translate-x-[90%] hover:transition-scale" : "")
                }
                onClick={hidden ? setHidden : () => {}}
            >
                <div className="w-full z-10 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    <div className="font-bold text-3xl pb-5 pt-2 mb-[-40px] ml-4 mt-12 text-left">
                        Description
                        <button
                            className="btn btn-ghost text-base ml-[-10px] hover:bg-transparent"
                            onClick={setHidden}
                        >
                            <svg
                                className="h-6 w-6 translate-y-1"
                                viewBox="0 0 48 48"
                                stroke="none"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.957,33.214L7.171,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.953-5.159-7.957-9.214
		                                L40.829,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.953,29.159,11.957,33.214z M16,24
    		                            c0-4.418,3.582-8,8-8c1.483,0,2.867,0.411,4.058,1.114l-3.035,3.035C24.694,20.062,24.356,20,24,20c-2.206,0-4,1.794-4,4
	    	                            c0,0.356,0.062,0.694,0.149,1.023l-3.035,3.035C16.411,26.867,16,25.483,16,24z M32,24c0,4.418-3.582,8-8,8
		                                c-1.483,0-2.867-0.411-4.058-1.114l3.035-3.035C23.306,27.938,23.644,28,24,28c2.206,0,4-1.794,4-4
		                                c0-0.356-0.062-0.694-0.149-1.023l3.035-3.035C31.589,21.133,32,22.517,32,24z"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="bg-base-300/80 rounded-box pt-8 overflow-y-auto max-h-[72vh]">
                        <label className="form-control ml-4">
                            <div className="label mb-[-5px]">
                                <span className="label-text">Description</span>
                            </div>
                            <textarea
                                className="textarea pb-32 mb-6 mr-4 h-42 bg-base-200 focus:outline-none"
                                placeholder=""
                                value={description}
                                onChange={onDescriptionValueChange}
                                onBlur={onDescriptionBlur}
                            ></textarea>
                        </label>

                        <div className="mb-5 px-4 text-left">
                            <label className="form-control my-5 mt-[-10px] w-32">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Price</span>
                                    <span className="label-text-alt mr-2">
                                        Fee: ${(price / 10).toPrecision(2)}
                                    </span>
                                </div>
                                <div className="join inline ml-1 bg-transparent text-nowrap">
                                    <div className="tooltip tooltip-bottom" data-tip={"Add a parameter"}>
                                        <input
                                            type="text"
                                            className="join-item input focus:outline-none input-bordered input-sm w-20 text-right"
                                            value={priceInputValue}
                                            onChange={onPriceChange}
                                            onBlur={onPriceBlur}
                                            disabled={
                                                !(
                                                    user &&
                                                    user.isSeller &&
                                                    user.invoicingData &&
                                                    user.invoicingData.address &&
                                                    user.invoicingData.name
                                                )
                                            }
                                        />
                                        <button
                                            className="btn btn-sm btn-outline join-item bordered"
                                            disabled
                                        >
                                            $
                                        </button>
                                    </div>
                                </div>
                            </label>
                            {price == 0 && (
                                <div className="flex w-full justify-start mb-3 ml-1">
                                    <a
                                        className="link text-sm mr-4"
                                        href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Free model licensed as: CC BY-NC-SA
                                    </a>
                                </div>
                            )}
                            <label className="form-control my-5 mt-[-10px]">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Social Url</span>
                                </div>
                                <input
                                    type="text"
                                    className="input focus:outline-none input-bordered input-sm w-64 text-left"
                                    value={url}
                                    onChange={onUrlValueChange}
                                    onBlur={onUrlBlur}
                                />
                            </label>

                            <label className="form-control my-5 mt-[-10px]">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">Tags</span>
                                </div>
                                <div className="join bg-transparent text-left text-nowrap mb-2 mr-2">
                                    <input
                                        type="text"
                                        className="join-item input focus:outline-none input-bordered input-sm w-20 text-right"
                                        value={tag}
                                        onChange={onTagValueChange}
                                        onBlur={onTagBlur}
                                    />
                                    <button
                                        className="btn btn-sm btn-outline join-item"
                                        onClick={addTagHandle}
                                    >
                                        add
                                    </button>
                                </div>
                                <div className="my-1">
                                    {tags.map((tag: string, index: number) => {
                                        return (
                                            <div
                                                key={"item_tag_" + index}
                                                className="badge mx-1 my-1 badge-outline"
                                            >
                                                {tag}
                                            </div>
                                        )
                                    })}
                                </div>
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <HexColorPicker color={color} onChange={setColor} />
                        </div>
                        <div className="mb-5 mt-5">
                            <label className="form-control ml-4">
                                <div className="label mb-[-5px]">
                                    <span className="label-text">
                                        {selectedBody ? selectedBody + " body description" : "Select a body"}
                                    </span>
                                </div>
                                <textarea
                                    className="textarea pb-32 mb-6 mr-4 h-32 bg-base-200 focus:outline-none overflow-hidden"
                                    placeholder=""
                                    disabled={!selectedBody}
                                    value={bodyDescriptions[selectedBody] ?? ""}
                                    onChange={onBodyDescriptionValueChange}
                                    onBlur={onBodyDescriptionBlur}
                                ></textarea>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupDescription
