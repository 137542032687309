import React, { useEffect } from "react"
import { useState, useCallback, useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { STLExporter } from "three/examples/jsm/exporters/STLExporter"

export type Property = {
    cellAddress: string
    alias: string
    name?: string
    type?: string
    value: string
    description?: string
    readonly?: boolean
}

export type Profile = {
    groupings: Grouping[]
    description?: string
    youtubeUrl?: string
    displayName: string
}

export type Placement = {
    x: number
    y: number
    z: number
    yaw: number
    pitch: number
    roll: number
}

export type SceneEntry = {
    [key: string]: Placement
}

export type Cad = {
    id: string
    title: string
    date: string
    tags: string[]
    author?: string
    userId: string
    url?: string
    description: string
    thumbnailUrl?: string
    images: string[]
    renderHash: string
    color: string
    bodies: string[]
    properties: Property[]
    scene: SceneEntry
    bodiesDescription: BodyDescription[]
    type: FileType
    price?: number
    abuseReports: AbuseReport[]
    reportsCount: number
    ratingSum: number
    ratingNumber: number
}

export type EditCad = {
    title?: string
    tags?: string[]
    url?: string
    description?: string
    color?: string
    bodiesDescription?: BodyDescription[]
    price?: number
}

export type BodyDescription = {
    bodyName: string
    description: string
}

export enum PriceFilter {
    ALL = "all",
    PAID = "paid",
    FREE = "free",
}

export enum ParametricFilter {
    ALL = "all",
    PARAMETRIC = "parametric",
}

export enum Sort {
    NEWEST = "newest",
    RATING = "rating",
    POPULAR = "popular",
    CLAIMS = "claims",
}

export type SearchParams = {
    keyword?: string
    price?: PriceFilter
    sort?: Sort
    parametric?: ParametricFilter
}

export enum AbuseType {
    COPYRIGHT = "copyright",
    TERMS = "terms&conditions",
    QUALITY = "quality",
    OTHER = "other",
}

enum UserType {
    moderator = "moderator",
    admin = "admin",
}

export type RenderParameters = {
    [cellAddress: string]: string
}

export enum FileType {
    FREECAD = "freecad",
    STL = "stl",
}

export type UploadCadParameters = {
    title: string
    tags: string[]
    url?: string
    color: string
    description: string
    properties?: UploadPropertyParameters[]
    bodiesDescription?: BodyDescription[]
    type: FileType
    price?: number
}

export type UploadPropertyParameters = {
    cellAddress: string
    name?: string
    type?: string
    description?: string
    readonly?: boolean
}

export enum TransactionType {
    BUY = "buy",
    SELL = "sell",
}

export enum TransactionStatus {
    PENDING = "pending",
    FAILED = "failed",
    COMPLETED = "completed",
}

export type Transaction = {
    id: string
    type: TransactionType
    amount: number
    cadIds: string[]
    groupingId?: string
    date: string
    status: TransactionStatus
}

export type Grouping = {
    id: string
    cads: Cad[]
    title: string
    description: string
    url: string
    price?: number
    rest?: boolean
}

export type EditGrouping = {
    cads?: string[]
    title?: string
    description?: string
    url?: string
    price?: number
}

export type User = {
    id: string
    thirdPartyUserIds: string[]
    email: string
    date: string
    displayName: string
    lastDisplayNameChange: string | null
    groupings: Grouping[]
    boughtIds: string[]
    transactions: Transaction[]
    sellerAccountId?: string
    isSeller: boolean
    invoicingData?: InvoicingData
    displayNameRemainingCooldown: number
    description?: string
    youtubeUrl?: string
    roles: string[]
}

export type InvoicingData = {
    name: string
    taxId: string
    address: string
}

type Response = {
    json: any | null
    statusCode: number
    contentType: string
}

export type Print = {
    id: string
    cadId: string
    userId: string
    comment: string
    image?: string[]
    rating: number
    userDisplayName: string
}

export type AbuseReport = {
    id: string
    reporterUserId: string
    type: AbuseType
    proofUrl?: string
    message: string
    processed: boolean
    comment?: string
}

const MOCKED_LOGIN = false
const MOCKED_MODERATOR = false

export class Api {
    baseUrl: string

    constructor(backendUrl: string) {
        this.baseUrl = backendUrl
    }

    async request(
        method: "POST" | "DELETE" | "GET",
        path: string,
        accessToken?: any,
        params?: any,
        files?: any,
    ): Promise<Response> {
        var data: FormData | URLSearchParams | null = null
        if (params || files) {
            data = files ? new FormData() : new URLSearchParams()

            for (const field in files) {
                if (Array.isArray(files[field])) {
                    var i = 0
                    for (const element of files[field]) {
                        ;(data as FormData).append(field, element)
                        i++
                    }
                } else {
                    ;(data as FormData).append(field, files[field])
                }
            }
            for (const field in params) {
                data.append(field, params[field])
            }
        }

        var requestHeaders: any = {}

        if (MOCKED_LOGIN) {
            requestHeaders = {
                "api-displayName": "printedmachines.com",
                "api-email": "test@test.test",
                "api-userId": "3ba8203d-bda1-4900-a92a-74276b1f2561",
            }

            if (MOCKED_MODERATOR) {
                requestHeaders["api-roles"] = UserType.admin + "," + UserType.moderator
            }
        }

        if (!files && params) {
            requestHeaders["Content-Type"] = "application/x-www-form-urlencoded"
        }

        if (accessToken) {
            requestHeaders["Authorization"] = `Bearer ${accessToken}`
        }

        var json = null
        var response = await fetch(new URL(path, this.baseUrl), {
            method,
            body: files ? data : data?.toString(),
            headers: requestHeaders,
        })

        try {
            json = await response.json()
        } catch (error) {
            console.log(error)
        }

        const contentType = response.headers.get("content-type") ?? ""
        return {
            json: json,
            statusCode: response.status,
            contentType,
        }
    }

    authHeaders(accessToken: string | null) {
        if (MOCKED_LOGIN) {
            const headers: any = {
                "api-displayName": "Test User One",
                "api-email": "test@test.test",
                "api-userId": "3ba8203d-bda1-4900-a92a-74276b1f2561",
            }
            if (MOCKED_MODERATOR) {
                headers["api-roles"] = UserType.admin + "," + UserType.moderator
            }

            return headers
        } else {
            if (accessToken) {
                return {
                    Authorization: `Bearer ${accessToken}`,
                }
            } else {
                return {}
            }
        }
    }

    async objToStl(file: File) {
        const loader = new OBJLoader()

        const text = await file.text()
        const group = loader.parse(text)

        const exporter = new STLExporter()
        const stl = exporter.parse(group, { binary: true })
        return stl
    }

    async getUser(accessToken: string) {
        const response = await this.request("GET", "/user", accessToken)
        if (response.statusCode != 200) {
            return undefined
        }
        return response.json as User
    }

    async getList(searchParams: SearchParams, page: Number, accessToken?: string): Promise<Cad[]> {
        const parameters = []

        parameters.push("page=" + page)

        if (searchParams.keyword) {
            parameters.push("keyword=" + searchParams.keyword)
        }

        if (searchParams.price) {
            parameters.push("price=" + searchParams.price)
        } else {
            parameters.push("price=" + PriceFilter.ALL)
        }

        if (searchParams.parametric) {
            parameters.push("parametric=" + searchParams.parametric)
        } else {
            parameters.push("parametric=" + ParametricFilter.ALL)
        }

        if (searchParams.sort) {
            parameters.push("sort=" + searchParams.sort)
        } else {
            parameters.push("sort=" + Sort.NEWEST)
        }

        const response = await this.request("GET", "/cad?" + parameters.join("&"), accessToken)

        if (response.statusCode != 200) {
            return []
        }
        return response.json as Cad[]
    }

    async getCad(id: string, accessToken?: string): Promise<Cad | undefined> {
        const response = await this.request("GET", "/cad/" + id, accessToken)
        if (response.statusCode != 200) {
            return undefined
        }
        const json = response.json as Cad
        return json
    }

    async renderCad(id: string, properties: RenderParameters, accessToken: string): Promise<Cad | undefined> {
        const response = await this.request("POST", "/cad/" + id + "/render", accessToken, {
            parameters: JSON.stringify(properties),
        })

        if (response.statusCode != 200) {
            return undefined
        }
        return response.json as Cad
    }

    async prerenderCad(file: File, accessToken: string): Promise<Cad | undefined> {
        const response = await this.request("POST", "/prerenderCad", accessToken, null, {
            file: file,
        })

        if (response.statusCode != 200) {
            return undefined
        }
        return response.json as Cad
    }

    async prerenderSTL(file: Blob, accessToken: string): Promise<Cad | undefined> {
        const response = await this.request("POST", "/prerenderSTL", accessToken, null, {
            file: file,
        })

        if (response.statusCode != 200) {
            return undefined
        }
        return response.json as Cad
    }

    async registerAsSeller(accessToken: string): Promise<string | undefined> {
        const response = await this.request("POST", "/user/seller", accessToken)
        if (response.statusCode != 200) {
            return undefined
        }
        return response.json.url
    }

    async upload(
        file: File,
        gif: Blob,
        uploadParameters: UploadCadParameters,
        accessToken: string,
    ): Promise<Cad | undefined> {
        const response = await this.request(
            "POST",
            "/cad",
            accessToken,
            {
                cad: JSON.stringify(uploadParameters),
            },
            {
                file: file,
                gif: gif,
            },
        )

        if (response.statusCode != 201) {
            return undefined
        }
        return response.json as Cad
    }

    async setDisplayName(newDisplayName: string, accessToken: string) {
        const response = await this.request("POST", "/user/displayName", accessToken, {
            displayName: newDisplayName,
        })

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async setInvoicingData(name: string, taxId: string | null, address: string, accessToken: string) {
        const params: any = {
            name,
            address,
        }

        if (taxId) {
            params.taxId = taxId
        }
        const response = await this.request("POST", "/user/invoicingData", accessToken, params)

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async setProfileData(description: string, youtubeUrl: string, accessToken: string) {
        const response = await this.request("POST", `/user/profile`, accessToken, {
            description,
            youtubeUrl,
        })

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async removeCad(cadId: string, accessToken: string) {
        const response = await this.request("DELETE", `/cad/${cadId}`, accessToken)

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async editCad(cadId: string, editCad: EditCad, accessToken: string) {
        const response = await this.request("POST", `/cad/${cadId}`, accessToken, {
            cad: JSON.stringify(editCad),
        })

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async addAbuseClaim(
        cadId: string,
        type: AbuseType,
        proofUrl: string | null,
        message: string,
        accessToken: string,
    ) {
        const params: any = {
            type,
            message,
        }

        if (proofUrl) {
            params["proofUrl"] = proofUrl
        }
        const response = await this.request("POST", `/cad/${cadId}/abuse`, accessToken, params)

        if (response.statusCode != 201) {
            return null
        }
        return response.json
    }

    async processAbuseClaim(
        cadId: string,
        abuseId: string,
        status: boolean,
        comment: string,
        accessToken: string,
    ) {
        const response = await this.request("POST", `/cad/${cadId}/abuse/${abuseId}/process`, accessToken, {
            status,
            comment,
        })

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async addGrouping(grouping: Grouping, accessToken: string) {
        const response = await this.request("POST", `/user/groupings`, accessToken, {
            grouping: JSON.stringify(grouping),
        })

        if (response.statusCode != 201) {
            return null
        }
        return response.json
    }

    async buyCad(cadId: string, accessToken: string) {
        const response = await this.request("POST", `/user/buyCad`, accessToken, {
            cadId,
        })

        if (response.statusCode != 200) {
            return null
        }
        return response.json.url
    }

    async buyGrouping(groupingId: string, accessToken: string) {
        const response = await this.request("POST", `/user/buyGrouping`, accessToken, {
            groupingId,
        })

        if (response.statusCode != 200) {
            return null
        }
        return response.json.url
    }

    async removeGrouping(groupingId: string, accessToken: string) {
        const response = await this.request("DELETE", `/user/groupings/${groupingId}`, accessToken)

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async editGrouping(groupingId: string, editGrouping: EditGrouping, accessToken: string) {
        const response = await this.request("POST", `/user/groupings/${groupingId}`, accessToken, {
            grouping: JSON.stringify(editGrouping),
        })

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async getPrints(cadId: string) {
        const response = await this.request("GET", `/cad/${cadId}/prints`)
        if (response.statusCode != 200) {
            return []
        }
        return response.json as Print[]
    }

    async addPrint(cadId: string, comment: string, rating: number, images: File[], accessToken: string) {
        const response = await this.request(
            "POST",
            `/user/print/`,
            accessToken,
            {
                cadId,
                comment,
                rating,
            },
            {
                "images[]": images,
            },
        )

        if (response.statusCode != 201) {
            return null
        }
        return response.json
    }

    async removePrint(printId: string, accessToken: string) {
        const response = await this.request("DELETE", `/user/print/${printId}`, accessToken)

        if (response.statusCode != 200) {
            return false
        }
        return true
    }

    async getUserGroupings(displayName: string, accessToken?: string) {
        const response = await this.request("GET", `/user/${displayName}/groupings`, accessToken)
        if (response.statusCode != 200) {
            return false
        }
        return response.json
    }

    async getOwnedCads(accessToken: string) {
        const response = await this.request("GET", `/user/owned`, accessToken)
        if (response.statusCode != 200) {
            return false
        }
        return response.json
    }
}

type ApiContext = {
    user: User | null
    getCadList: (searchParams: SearchParams, page: number) => Promise<Cad[]>
    getCad: (id: string) => Promise<Cad | undefined>
    renderCad: (id: string, parameters: RenderParameters) => Promise<Cad | undefined>
    prerenderCad: (file: File) => Promise<Cad | undefined>
    prerenderStl: (file: Blob) => Promise<Cad | undefined>
    registerAsSeller: () => Promise<string | undefined>
    updateUser: () => Promise<any>
    objToStl: (file: File) => Promise<DataView>
    upload: (file: File, gif: Blob, uploadParameters: UploadCadParameters) => Promise<Cad | undefined>
    setDisplayName: (newDisplayName: string) => Promise<boolean>
    setInvoicingData: (name: string, taxId: string | null, address: string) => Promise<boolean>
    setProfileData: (description: string, youtubeUrl: string) => Promise<boolean>
    removeCad: (cadId: string) => Promise<boolean>
    editCad: (cadId: string, editCad: EditCad) => Promise<boolean>
    addAbuseClaim: (
        cadId: string,
        type: AbuseType,
        proofUrl: string | null,
        message: string,
    ) => Promise<AbuseReport | null>
    processAbuseClaim: (cadId: string, abuseId: string, status: boolean, comment: string) => Promise<boolean>
    addGrouping: (grouping: Grouping) => Promise<Grouping | null>
    buyCad: (cadId: string) => Promise<string | null>
    buyGrouping: (groupingId: string) => Promise<string | null>
    removeGrouping: (groupingId: string) => Promise<boolean>
    editGrouping: (groupingId: string, grouping: EditGrouping) => Promise<boolean>
    addPrint: (cadId: string, comment: string, rating: number, images: File[]) => Promise<Print | null>
    removePrint: (printId: string) => Promise<boolean>
    getUserGroupings: (displayName: string) => Promise<Profile | null>
    getOwnedCads: () => Promise<Cad[]>
    getPrints: (cadId: string) => Promise<Print[]>
    isCadOwned: (cadId: string) => boolean
    isMod: () => boolean
    login: () => void
    logout: () => void
    authHeaders: () => any
    apiUrl: string
    accessToken: string | null
}

export const ApiContext = React.createContext<ApiContext>({} as ApiContext)

const savedUser = localStorage.getItem("User")
export function ApiContextProvider({ children }: any) {

    const { isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0()
    const { REACT_APP_API_URL, REACT_APP_AUTH0_AUDIENCE } = process.env
    const [user, setUser] = useState<User | null>(savedUser ? JSON.parse(savedUser) : null)
    const [accessToken, setAccessToken] = useState<string | null>(null)

    const [api] = useState<Api>(new Api(REACT_APP_API_URL || "http://localhost"))

    const loginCallback = useCallback(() => {
        return loginWithRedirect()
    }, [user])

    const logoutCallback = useCallback(() => {
        setAccessToken(null)
        setUser(null)
        localStorage.removeItem("User")
        return logout()
    }, [user])

    useEffect(() => {
        ;(async () => {
            if (isAuthenticated && (!accessToken || !user)) {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: REACT_APP_AUTH0_AUDIENCE,
                        scope: "read:current_user read:profile",
                    },
                })
                setAccessToken(accessToken)

                const user = await api.getUser(accessToken)
                if (user) {
                    localStorage.setItem("User", JSON.stringify(user))
                    setUser(user)
                } else {
                    setUser(null)
                    localStorage.removeItem("User")
                }
            }
        })()
    }, [isAuthenticated, accessToken, isLoading])

    const updateUser = useCallback(async () => {
        if (!accessToken) {
            return
        }
        const user = await api.getUser(accessToken)
        if (user) {
            localStorage.setItem("User", JSON.stringify(user))
            setUser(user)
        } else {
            setUser(null)
            localStorage.removeItem("User")
        }
    }, [accessToken])

    const getCadList = useCallback(
        async (searchParams: SearchParams, page: number) => {
            return await api.getList(searchParams, page, accessToken ? accessToken : undefined)
        },
        [accessToken],
    )

    const getCad = useCallback(
        async (id: string) => {
            return await api.getCad(id, accessToken ? accessToken : undefined)
        },
        [accessToken],
    )

    const renderCad = useCallback(
        async (id: string, parameters: RenderParameters) => {
            if (!accessToken) {
                return undefined
            }
            return await api.renderCad(id, parameters, accessToken)
        },
        [accessToken],
    )

    const prerenderCad = useCallback(
        async (file: File) => {
            if (!accessToken) {
                return undefined
            }
            return await api.prerenderCad(file, accessToken)
        },
        [accessToken],
    )

    const prerenderStl = useCallback(
        async (file: Blob) => {
            if (!accessToken) {
                return undefined
            }
            return await api.prerenderSTL(file, accessToken)
        },
        [accessToken],
    )

    const registerAsSeller = useCallback(async () => {
        if (!accessToken) {
            return undefined
        }
        return await api.registerAsSeller(accessToken)
    }, [accessToken])

    const objToStl = useCallback(
        async (file: File) => {
            return await api.objToStl(file)
        },
        [accessToken],
    )

    const upload = useCallback(
        async (file: File, gif: Blob, uploadParameters: UploadCadParameters) => {
            if (!accessToken) {
                return undefined
            }

            const uploadResult = await api.upload(file, gif, uploadParameters, accessToken)
            await updateUser()
            return uploadResult
        },
        [accessToken],
    )

    const setDisplayName = useCallback(
        async (newDisplayName: string) => {
            if (!accessToken) {
                return false
            }

            const setDisplayNameResult = await api.setDisplayName(newDisplayName, accessToken)
            await updateUser()
            return setDisplayNameResult
        },
        [accessToken],
    )

    const setInvoicingData = useCallback(
        async (name: string, taxId: string | null, address: string) => {
            if (!accessToken) {
                return false
            }
            const setInvoicingDataResult = await api.setInvoicingData(name, taxId, address, accessToken)
            await updateUser()
            return setInvoicingDataResult
        },
        [accessToken],
    )

    const setProfileData = useCallback(
        async (description: string, youtubeUrl: string) => {
            if (!accessToken) {
                return false
            }
            const setProfileDataResult = await api.setProfileData(description, youtubeUrl, accessToken)
            await updateUser()
            return setProfileDataResult
        },
        [accessToken],
    )

    const removeCad = useCallback(
        async (cadId: string) => {
            if (!accessToken) {
                return false
            }

            const removeCadResult = await api.removeCad(cadId, accessToken)
            await updateUser()
            return removeCadResult
        },
        [accessToken],
    )

    const editCad = useCallback(
        async (cadId: string, editCad: EditCad) => {
            if (!accessToken) {
                return false
            }

            const editCadResult = await api.editCad(cadId, editCad, accessToken)
            await updateUser()
            return editCadResult
        },
        [accessToken],
    )

    const addAbuseClaim = useCallback(
        async (cadId: string, type: AbuseType, proofUrl: string | null, message: string) => {
            if (!accessToken) {
                return false
            }
            return await api.addAbuseClaim(cadId, type, proofUrl, message, accessToken)
        },
        [accessToken],
    )

    const processAbuseClaim = useCallback(
        async (cadId: string, abuseId: string, status: boolean, comment: string) => {
            if (!accessToken) {
                return false
            }

            return await api.processAbuseClaim(cadId, abuseId, status, comment, accessToken)
        },
        [accessToken],
    )

    const addGrouping = useCallback(
        async (grouping: Grouping) => {
            if (!accessToken) {
                return undefined
            }

            const addGroupingResult = await api.addGrouping(grouping, accessToken)
            await updateUser()
            return addGroupingResult
        },
        [accessToken],
    )

    const buyCad = useCallback(
        async (cadId: string) => {
            if (!accessToken) {
                return undefined
            }

            return await api.buyCad(cadId, accessToken)
        },
        [accessToken],
    )

    const buyGrouping = useCallback(
        async (groupingId: string) => {
            if (!accessToken) {
                return undefined
            }

            return await api.buyGrouping(groupingId, accessToken)
        },
        [accessToken],
    )

    const removeGrouping = useCallback(
        async (groupingId: string) => {
            if (!accessToken) {
                return false
            }

            const removeGroupingResult = await api.removeGrouping(groupingId, accessToken)
            await updateUser()
            return removeGroupingResult
        },
        [accessToken],
    )

    const editGrouping = useCallback(
        async (groupingId: string, editGrouping: EditGrouping) => {
            if (!accessToken) {
                return false
            }

            const editGroupingResult = await api.editGrouping(groupingId, editGrouping, accessToken)
            await updateUser()
            return editGroupingResult
        },
        [accessToken],
    )

    const getPrints = useCallback(async (cadId: string) => {
        return await api.getPrints(cadId)
    }, [])

    const addPrint = useCallback(
        async (cadId: string, comment: string, rating: number, images: File[]) => {
            if (!accessToken) {
                return false
            }

            return await api.addPrint(cadId, comment, rating, images, accessToken)
        },
        [accessToken],
    )

    const removePrint = useCallback(
        async (printId: string) => {
            if (!accessToken) {
                return false
            }

            return await api.removePrint(printId, accessToken)
        },
        [accessToken],
    )

    const getUserGroupings = useCallback(
        async (displayName: string) => {
            return await api.getUserGroupings(displayName, accessToken ? accessToken : undefined)
        },
        [accessToken],
    )

    const getOwnedCads = useCallback(async () => {
        if (!accessToken) {
            return []
        }

        return await api.getOwnedCads(accessToken)
    }, [accessToken])

    const isCadOwned = useCallback(
        (cadId: string) => {
            if (!user) {
                return false
            }
            return user.boughtIds.includes(cadId)
        },
        [accessToken, user],
    )

    const isMod = useCallback(() => {
        if (!user) {
            return false
        }
        return user.roles.includes(UserType.moderator) || user.roles.includes(UserType.admin)
    }, [accessToken])

    const authHeaders = useCallback(() => {
        return api.authHeaders(accessToken)
    }, [accessToken])

    const exposedData: ApiContext = {
        getCadList,
        getCad,
        renderCad,
        prerenderCad,
        prerenderStl,
        registerAsSeller,
        updateUser,
        objToStl,
        upload,
        setDisplayName,
        setInvoicingData,
        removeCad,
        editCad,
        addAbuseClaim,
        processAbuseClaim,
        addGrouping,
        buyCad,
        buyGrouping,
        removeGrouping,
        editGrouping,
        getPrints,
        addPrint,
        removePrint,
        getUserGroupings,
        login: loginCallback,
        logout: logoutCallback,
        isCadOwned,
        isMod,
        authHeaders,
        getOwnedCads,
        setProfileData,
        apiUrl: api.baseUrl,
        user,
        accessToken,
    }
    return <ApiContext.Provider value={exposedData}>{children}</ApiContext.Provider>
}

export const useApi = () => {
    return useContext(ApiContext)
}
